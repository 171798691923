import { Box } from '@chakra-ui/layout';
import { IMessage, Message } from './Message';
import { NewMessagePlaceholder } from './NewMessagePlaceholder';

interface Props {
    messages: any[];
    reloadData: any;
    id: string;
    type: string;
    isDisabled?: boolean;
}

export function Bacheca({ messages = [], isDisabled = false, ...props }: Props) {
    return (
        <Box mt="2">
            {messages.map((message, index) => (
                <Message key={index} message={message} />
            ))}
            {!isDisabled && <NewMessagePlaceholder reloadData={props.reloadData} id={props.id} type={props.type} />}
        </Box>
    );
}
