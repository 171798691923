import { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../components/shared/Loader';
import { columns } from '../../components/shared/Table';
import { AppContext, IContext } from '../../contexts/AppContext';
import { getAll } from '../../services/crud';
import { Documento } from './Documento';
import { Email } from './Email';
import { Fascicolo } from './Fascicolo';
import { Pratica } from './Pratica';

const types = ['email', 'pratiche', 'fascicoli', 'autoesitati'];

export function Detail() {
    const { type } = useParams<{ type: 'email' | 'pratiche' | 'fascicoli' | 'documenti' | 'autoesitati' }>();
    const { setPraticaManualModalWarningData, setShowDocumentaleModal, setShowFascicoloDeleteWarningModal } =
        useContext(AppContext) as IContext;

    const history = useHistory();
    const [data, setData] = useState<{ [key: string]: any }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loaderMessage, setLoaderMessage] = useState<string>('');
    const uri = history.location.pathname;
    let refreshPratiche;

    useEffect(() => {
        if (!types.includes(type)) {
            history.push('/dashboard');
        }
        loadData();
        return () => {
            if (refreshPratiche) clearInterval(refreshPratiche);
        };
    }, [type]);

    function loadData() {
        setIsLoading(true);
        getAll(uri)
            .then((data) => {
                setIsLoading(false);
                setData(data);
                if (type === 'pratiche' && data.processing && !refreshPratiche) {
                    refreshPratiche = setInterval(loadData, 5000); // 5 secondi
                }
                if (refreshPratiche && ((type === 'pratiche' && !data.processing) || type !== 'pratiche')) {
                    clearInterval(refreshPratiche);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    function getDefaultIndex(type: string): number[] {
        switch (type) {
            case 'email':
                return [0, 1, 2];
            case 'pratiche':
                return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            default:
                return [0, 1];
        }
    }

    return (
        <Loader isLoading={isLoading} message={loaderMessage}>
            <Flex flexDir="column">
                <Heading size="xl" mb="8" pl="4">
                    Dettaglio {type}
                </Heading>
                <Accordion defaultIndex={getDefaultIndex(type)} allowMultiple allowToggle>
                    <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                <Heading size="md">{type.charAt(0).toUpperCase() + type.slice(1)}</Heading>
                            </Box>
                            {type === 'pratiche' && data?.created_at && data.created_at.length > 0 && (
                                <Flex>
                                    <Text color="gray.500" mr="2">
                                        {`Data creazione:`}
                                    </Text>
                                    <Text fontWeight="600" mr="2">
                                        {data?.created_at}
                                    </Text>
                                </Flex>
                            )}
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Box w="100%">
                                {columns[type].map((column) => {
                                    return (
                                        <Flex key={column.value} alignItems="center">
                                            <Box w="30%">{column.label}: </Box>
                                            <Box fontWeight="600">
                                                {column.render
                                                    ? column.render(
                                                          column.value,
                                                          data[column.value],
                                                          null,
                                                          null,
                                                          data,
                                                          setIsLoading,
                                                          loadData,
                                                          setPraticaManualModalWarningData,
                                                          setShowDocumentaleModal,
                                                          setShowFascicoloDeleteWarningModal,
                                                      )
                                                    : data[column.value]}
                                            </Box>
                                        </Flex>
                                    );
                                })}
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>

                    {type === 'email' ? (
                        <Email data={data} />
                    ) : type === 'pratiche' ? (
                        <Pratica
                            data={data}
                            reloadData={loadData}
                            setIsLoading={setIsLoading}
                            setLoaderMessage={setLoaderMessage}
                        />
                    ) : type === 'fascicoli' ? (
                        <Fascicolo data={data} reloadData={loadData} setIsLoading={setIsLoading} />
                    ) : (
                        <Documento data={data} />
                    )}
                </Accordion>
            </Flex>
        </Loader>
    );
}
