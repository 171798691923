import { DownloadIcon } from '@chakra-ui/icons';
import { Flex, Heading } from '@chakra-ui/layout';
import { Button, Box } from '@chakra-ui/react';
import { BodyTable } from '../../components/shared/Table/BodyTable';

interface Props {
    id?: any;
    bgColor?: string;
    height: string;
    title: string;
    columns: any;
    data: any;
    buttons?: any;
    onClickQualitative?: any;
}

export function DashboardBox({ bgColor = 'orange.200', height, title, columns, data, ...props }: Props) {
    return (
        <Flex
            id={props.id}
            mb="4"
            px="6"
            py="3"
            height={height}
            width="100%"
            flexDir="column"
            borderRadius="3xl"
            justifyContent="space-between"
            bgColor={bgColor}
        >
            <Flex direction="row" justifyContent={'space-between'} alignItems="center">
                <Heading fontSize="4xl">{title}</Heading>
                <Button
                    size="sm"
                    color={'blue.500'}
                    onClick={() => {
                        props.onClickQualitative();
                    }}
                    rightIcon={<DownloadIcon />}
                >
                    Stats qualitative
                </Button>
            </Flex>
            <Heading fontSize="6xl" fontWeight="100" textAlign="left">
                <BodyTable columns={columns} data={data} {...props} />
            </Heading>
        </Flex>
    );
}
