import { useState } from 'react';
import { Button, Icon, Input, Link, Flex, HStack, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import {
    CgChevronRight,
    CgChevronLeft,
    CgChevronDoubleRight,
    CgChevronDoubleLeft,
    CgChevronDown,
    CgBorderStyleDotted,
} from 'react-icons/cg';

const activeStyle = {
    bg: 'gray.200',
    color: 'gray.500',
};

interface Props {
    total: number;
    rowForPage: number;
    actualPage: number;
    onLimitChange: (limit: number) => any;
    onPageChange: (skip: number) => any;
}

export function Pagination({ total = 1, rowForPage = 20, actualPage = 1, ...props }: Props) {
    const [actualMaxPage, setActualMaxPage] = useState(8);

    const PagButton = (props: any) => {
        return (
            <Button
                mx={1}
                px={4}
                py={2}
                rounded="md"
                bg="white"
                color="gray.700"
                opacity={props.disabled && 0.6}
                _hover={!props.disabled && activeStyle}
                cursor={props.disabled && 'not-allowed'}
                {...(props.active && activeStyle)}
                {...props}
            >
                {props.children}
            </Button>
        );
    };

    const MButton = (props: any) => {
        const DoubleArrow = props.left ? CgChevronDoubleLeft : CgChevronDoubleRight;
        const [hovered, setHovered] = useState(false);
        return (
            <Link
                w={4}
                py={2}
                color="gray.700"
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
                cursor="pointer"
                textAlign="center"
            >
                {hovered ? (
                    <Icon as={DoubleArrow} boxSize={3} cursor="pointer" color="gray.800" />
                ) : (
                    <Icon as={CgBorderStyleDotted} color="gray.200" boxSize={4} opacity={0.5} />
                )}
            </Link>
        );
    };

    const pagesButton: any[] = [];
    const numberPages = Math.ceil(total / rowForPage);
    if (numberPages > 10) {
        pagesButton.push(
            <PagButton active={1 === actualPage} onClick={() => handlePageButtonClick(1)} id={1}>
                {1}
            </PagButton>,
        );
        pagesButton.push(
            <PagButton active={2 === actualPage} onClick={() => handlePageButtonClick(2)} id={2}>
                {2}
            </PagButton>,
        );
        if (actualPage === actualMaxPage && actualPage !== numberPages - 2) {
            setActualMaxPage(actualMaxPage + 1);
            Array(6)
                .fill(0)
                .map((_, i) =>
                    pagesButton.push(
                        <PagButton
                            active={actualMaxPage - 4 + i === actualPage}
                            onClick={() => handlePageButtonClick(actualMaxPage - 4 + i)}
                            id={actualMaxPage - 4 + i}
                        >
                            {actualMaxPage - 4 + i}
                        </PagButton>,
                    ),
                );
        } else if (actualPage === actualMaxPage - 5 && actualPage !== 3) {
            setActualMaxPage(actualMaxPage - 1);
            Array(6)
                .fill(0)
                .map((_, i) =>
                    pagesButton.push(
                        <PagButton
                            active={actualMaxPage - 6 + i === actualPage}
                            onClick={() => handlePageButtonClick(actualMaxPage - 6 + i)}
                            id={actualMaxPage - 6 + i}
                        >
                            {actualMaxPage - 7 + i}
                        </PagButton>,
                    ),
                );
        } else {
            if (actualMaxPage !== 8) {
                pagesButton.push(
                    <PagButton active={false} id={'separator'}>
                        ...
                    </PagButton>,
                );
            }
            Array(6)
                .fill(0)
                .map((_, i) =>
                    pagesButton.push(
                        <PagButton
                            active={actualMaxPage - 5 + i === actualPage}
                            onClick={() => handlePageButtonClick(actualMaxPage - 5 + i)}
                            id={actualMaxPage - 5 + i}
                        >
                            {actualMaxPage - 5 + i}
                        </PagButton>,
                    ),
                );
        }
        pagesButton.push(
            <PagButton active={false} id={'separator'}>
                ...
            </PagButton>,
        );
        pagesButton.push(
            <PagButton
                active={numberPages - 1 === actualPage}
                onClick={() => handlePageButtonClick(numberPages - 1)}
                id={numberPages - 1}
            >
                {numberPages - 1}
            </PagButton>,
        );
        pagesButton.push(
            <PagButton
                active={numberPages === actualPage}
                onClick={() => handlePageButtonClick(numberPages)}
                id={numberPages}
            >
                {numberPages}
            </PagButton>,
        );
    } else {
        Array(numberPages > 0 ? numberPages : 1)
            .fill(0)
            .map((_, i) =>
                pagesButton.push(
                    <PagButton active={i + 1 === actualPage} onClick={() => handlePageButtonClick(i + 1)} id={i}>
                        {i + 1}
                    </PagButton>,
                ),
            );
    }
    function handlePageButtonClick(pageSelected: number) {
        if (pageSelected < 3) {
            setActualMaxPage(8);
        }
        if (pageSelected > 0 && pageSelected < numberPages + 1) props.onPageChange(pageSelected);
    }

    function handleButtonLimitClick(limit: number) {
        props.onLimitChange(limit);
    }

    if (total === 0) {
        return null;
    }
    return (
        <Flex bg="gray.50" p={10} w="full" alignItems="center" justifyContent="center">
            <HStack>
                <PagButton onClick={() => handlePageButtonClick(actualPage - 1)}>
                    <Icon as={CgChevronLeft} color="gray.700" boxSize={4} />
                </PagButton>
                {pagesButton}
                <PagButton onClick={() => handlePageButtonClick(actualPage + 1)}>
                    <Icon as={CgChevronRight} color="gray.700" boxSize={4} />
                </PagButton>
                <Menu>
                    <MenuButton ml={1} as={Button} rightIcon={<CgChevronDown />}>
                        {rowForPage} / page
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => handleButtonLimitClick(20)}>20 / page</MenuItem>
                        <MenuItem onClick={() => handleButtonLimitClick(50)}>50 / page</MenuItem>
                        <MenuItem onClick={() => handleButtonLimitClick(100)}>100 / page</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
        </Flex>
    );
}
