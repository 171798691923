import { useContext, useState } from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tag,
    useToast,
    Link,
} from '@chakra-ui/react';
import urljoin from 'url-join';
import { AppContext, IContext } from 'contexts/AppContext';
import { basePath, baseURL } from '../../services/api';
import { createOne, getAll } from '../../services/crud';
import DocViewer, { DocViewerRenderers } from './React-Doc-Viewer';

export interface IDocument {
    _id: string;
    nome_file: string;
    dimensione: string;
    pratica: any;
    email: any;
    uri?: string;
    page_num?: string;
    type: string;
    total_pages?: string;
    content: string;
}

const typeColor: { [key: string]: any } = {
    MIC: 'blue',
    ALTRO: 'gray',
    CARICATI: 'teal',
    ORFANO: 'yellow',
};

interface Props {
    doc: IDocument;
    documentSize?: number;
    setIsLoading?: any;
    reloadData?: any;
}

export function Document({ doc, documentSize, setIsLoading, reloadData }: Props) {
    const { setDocument } = useContext(AppContext) as IContext;
    const [previewIsOpen, setPreviewIsOpen] = useState(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [docsUri, setDocsUri] = useState<Array<any>>([{ uri: undefined }]);
    const toast = useToast();

    function getUriFile(documentId = '') {
        return urljoin(baseURL, basePath, '/documenti/file/', documentId);
    }

    function getFileAndOpenPreview(documentId: string) {
        setPreviewIsOpen(true);
        setDocsUri([{ uri: getUriFile(documentId) }]);
    }

    function downloadFile(documentId: string) {
        setIsDownloadLoading(true);
        getAll('/documenti/file/'.concat(documentId), { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc?.nome_file?.replaceAll('##', '.')); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsDownloadLoading(false);
            })
            .catch(() => setIsDownloadLoading(false));
    }

    return (
        <Box w="100%" border="1px solid" borderColor="gray.300" my="4" p="4" fontSize="sm">
            <Flex justifyContent="space-between">
                <Tag mb={4} size="md" colorScheme={typeColor[doc.type] ? typeColor[doc.type] : 'gray'}>
                    {doc.type ? doc.type.toUpperCase() : ''}
                </Tag>
                {documentSize && (
                    <Button
                        colorScheme="red"
                        size="sm"
                        isDisabled={documentSize === 1}
                        onClick={() => {
                            setIsLoading(true);
                            createOne('pratiche/document/delete/', {
                                practice_id: doc.pratica.id,
                                document_id: doc._id,
                            }).then((response) => {
                                setIsLoading(false);
                                reloadData();
                                return toast({
                                    title: 'Documento eliminato con successo',
                                    status: 'success',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            });
                        }}
                    >
                        Elimina
                    </Button>
                )}
            </Flex>
            <Flex>
                <Box w="30%">ID del Documento: </Box>
                <Box fontWeight="600">{doc._id}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Nome del file: </Box>
                <Box fontWeight="600">{doc?.nome_file?.replaceAll('##', '.')}</Box>
                <Button
                    onClick={() => getFileAndOpenPreview(doc._id)}
                    variant="link"
                    colorScheme="blue"
                    size="sm"
                    ml="4"
                >
                    Anteprima
                </Button>
                <Button
                    isLoading={isDownloadLoading}
                    loadingText="Download in corso"
                    variant="link"
                    colorScheme="blue"
                    size="sm"
                    ml="4"
                    onClick={() => downloadFile(doc._id)}
                >
                    Scarica
                </Button>
            </Flex>
            {doc?.email?.label_id && (
                <Flex>
                    <Box w="30%">Email da cui è stato generato il Documento: </Box>
                    <Box fontWeight="500" color="blue.400">
                        <Link href={`/email/${doc?.email?.id}`}>{doc?.email?.label_id}</Link>
                    </Box>
                </Flex>
            )}
            <Flex>
                <Box w="30%">Pratica a cui è assegnato il Documento: </Box>
                <Box fontWeight="500" color="blue.400">
                    <Link href={`/pratiche/${doc?.pratica?.id}`}>{doc?.pratica?.label_id}</Link>
                    <Button
                        onClick={() => {
                            setDocument(doc);
                            toast({
                                title: 'Documento '.concat(doc.nome_file).concat(' copiato'),
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            });
                        }}
                        variant="link"
                        colorScheme="blue"
                        size="sm"
                        ml="4"
                    >
                        Sposta
                    </Button>
                </Box>
            </Flex>
            {doc.page_num && doc.total_pages && (
                <Flex>
                    <Box w="30%">Pagina: </Box>
                    <Box fontWeight="600">
                        {doc.page_num.toString()?.concat(' di ').concat(doc.total_pages.toString())}
                    </Box>
                </Flex>
            )}
            <Modal isOpen={previewIsOpen} onClose={() => setPreviewIsOpen(false)} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{doc.nome_file?.replaceAll('##', '.')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ alignSelf: 'center' }}>
                        {docsUri[0].uri && (
                            <DocViewer
                                documents={docsUri}
                                style={{ width: 800, height: 1050 }}
                                pluginRenderers={DocViewerRenderers}
                                config={{
                                    header: {
                                        disableHeader: true,
                                    },
                                }}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}
