import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import { customTheme } from 'assets';
import App from './App';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './services/auth';

// RIMUOVO CONSOLE LOG IN PRODUZIONE
if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
}

ReactDOM.render(
    <React.StrictMode>
        <AzureAD provider={authProvider}>
            {({ login, logout, authenticationState }: any) => (
                <ChakraProvider theme={customTheme}>
                    <App authenticationState={authenticationState} onLogout={logout} onLogin={login} />
                </ChakraProvider>
            )}
        </AzureAD>
    </React.StrictMode>,
    document.getElementById('root'),
);
