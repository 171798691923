import { Box, Flex } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/react';

export function EmailTread({ email }) {
    return (
        <Box w="100%" border="1px solid" borderColor="gray.300" my="4" p="4" fontSize="sm">
            <Flex>
                <Box w="30%">ID email: </Box>
                <Box fontWeight="500" color="blue.400">
                    <Link href={`/email/${email}`}>{email}</Link>
                </Box>
            </Flex>
        </Box>
    );
}
