import { useEffect, useState } from 'react';
import { Flex, Heading } from '@chakra-ui/layout';
import {
    Button,
    createStandaloneToast,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Select,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { Td } from '@chakra-ui/table';
import { FcApproval, FcMediumPriority, FcHighPriority } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';
import { deleteOne, getAll, updateOne } from 'services/crud';
import { authProvider } from '../../../services/auth';
import DatePicker from '../DatePicker/date-picker';
import { Loader } from '../Loader';
import { BodyTable } from './BodyTable';
import { Pagination } from './Pagination';
import { Column, TableProps } from './types';

export const columns: {
    [key: string]: Column[];
} = {
    email: [
        { label: 'Mittente', value: 'mittente', type: 'string' },
        {
            label: 'Oggetto',
            value: 'oggetto',
            type: 'string',
            render: (key: string, value: any) => (
                <Td key={key} maxW="35rem">
                    <Text isTruncated>{value}</Text>
                </Td>
            ),
        },
        {
            label: 'Data',
            value: 'data',
            type: 'date',
            render: (key: string, value: any) => <Td key={value}>{getDateFormatted(value, true)}</Td>,
        },
        { label: 'Compagnia', value: 'compagnia', type: 'string', options: 'RMA,ITA' },
        {
            label: 'Stato',
            value: 'status',
            render: (key: string, value: any) => <Td key={key}>{getEmailStatusIcon(value)}</Td>,
        },
    ],
    pratiche: [
        { label: 'Nome', value: 'label_id', type: 'string', notShow: true, filter: false },
        { label: 'Contraente', value: 'contraente', type: 'string' },
        { label: 'Numero polizza', value: 'numero_polizza', type: 'string' },
        {
            label: 'Data',
            value: 'data_operazione',
            type: 'date',
        },
        { label: 'Tipologia', value: 'tipo_operazione', type: 'string', options: 'incasso,pagamento' },
        { label: 'Compagnia', value: 'compagnia', type: 'string', options: 'RMA,ITA' },
        { label: 'Importo', value: 'importo', type: 'number', filter: false },
        { label: 'Presa in carico', value: 'presa_in_carico', type: 'string', filter: false },
        {
            label: 'Stato',
            value: 'status',
            render: (
                key: string,
                value: any,
                keyField: any,
                section: any,
                row: any,
                setIsLoading: any,
                reloadData: any,
                setPraticaManualModalWarningData: (pratica: string) => any,
                setShowDocumentaleModal: (show: boolean) => any,
            ) => <Td key={key}>{getPraticheStatusIcon(value, row?.wintar_response?.wintar_exists)}</Td>,
        },
        {
            label: 'Azioni',
            value: '_id',
            notShow: true,
            render: (
                key: string,
                value: any,
                keyField: any,
                section: any,
                row: any,
                setIsLoading: any,
                reloadData: any,
                setPraticaManualModalWarningData: (pratica: string) => any,
                setShowDocumentaleModal: (show: boolean) => any,
            ) => (
                <div>
                    <Button
                        colorScheme="yellow"
                        isDisabled={
                            row?.estrazione?.find((estrazione: any) => !estrazione.valid_status) ||
                            !row?.presa_in_carico ||
                            row?.status?.manual_check_required ||
                            (row?.intermediario_entities &&
                                row.intermediario_entities.find(
                                    (intermediario) => !intermediario.valid && intermediario.mandatory_upload,
                                ))
                        }
                        onClick={() => setShowDocumentaleModal(true)}
                        size="sm"
                    >
                        Invia a documentale
                    </Button>
                    <Button
                        ml="0.6rem"
                        colorScheme="yellow"
                        isDisabled={row?.status?.manual || !row?.presa_in_carico}
                        onClick={() => setPraticaManualModalWarningData(value)}
                        size="sm"
                    >
                        {row?.status?.manual ? 'Pratica in gestione manuale' : 'Gestione manuale'}
                    </Button>
                    <Button
                        ml="0.6rem"
                        colorScheme="blue"
                        isDisabled={row?.status?.manual}
                        onClick={() => setUserHolderPratica(value, setIsLoading, reloadData)}
                        size="sm"
                    >
                        Prendi in carico
                    </Button>
                </div>
            ),
        },
    ],
    fascicoli: [
        { label: 'Contraente', value: 'contraente', type: 'string' },
        { label: 'Numero polizza', value: 'numero_polizza', type: 'string' },
        { label: 'Compagnia', value: 'compagnia', type: 'string', options: 'RMA,ITA' },
        { label: 'Eccedenza', value: 'eccedenza', type: 'number', filter: false },
        {
            label: 'Stato',
            value: 'scarto_reinvestimento',
            render: (key: string, value: any) => <Td key={key}>{getFascicoliStatus(value)}</Td>,
        },
        {
            label: 'Azioni',
            value: '_id',
            notShow: true,
            render: (
                key: string,
                value: any,
                keyField: any,
                section: any,
                row: any,
                setIsLoading: any,
                reloadData: any,
                setPraticaManualModalWarningData: (pratica: string) => any,
                setShowDocumentaleModal: (show: boolean) => any,
                setShowFascicoloDeleteWarningModal: (show: boolean) => any,
            ) => (
                <div>
                    <Button colorScheme="red" onClick={() => setShowFascicoloDeleteWarningModal(true)} size="sm">
                        Elimina
                    </Button>
                </div>
            ),
        },
    ],
    documenti: [
        { label: 'Nome file', value: 'nome_file', type: 'string' },
        { label: 'Dimensione', value: 'dimensione', type: 'string' },
        { label: 'Email', value: 'email', type: 'string' },
        { label: 'Pratica', value: 'pratica', type: 'string' },
    ],
    autoesitati: [
        { label: 'Contraente', value: 'contraente', type: 'string' },
        { label: 'Numero polizza', value: 'numero_polizza', type: 'string' },
        { label: 'Tipologia', value: 'tipologia', type: 'string' },
        { label: 'Importo', value: 'importo', type: 'number' },
        { label: 'Data', value: 'data', type: 'date' },
    ],
    firme: [
        { label: 'Expected', value: 'expected' },
        { label: 'Legacy', value: 'legacy' },
    ],
};

function setUserHolderPratica(praticaId: string, setIsLoading: any, reloadData: any) {
    const toast = createStandaloneToast();
    setIsLoading(true);
    updateOne('pratiche', praticaId, {
        codice_utente: authProvider?.getAccount()?.userName,
        presa_in_carico: authProvider?.getAccount()?.userName,
    })
        .then((response) => {
            setIsLoading(false);
            reloadData();
            return toast({
                title: 'Pratica presa in carico correttamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        })
        .catch((error) => {
            setIsLoading(false);
            return toast({
                title: 'Errore imprevisto durante la presa in carico',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        });
}

export function getDateFormatted(date: Date, withHour: boolean) {
    if (withHour)
        return new Date(date).toLocaleDateString('it', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    else
        return new Date(date).toLocaleDateString('it', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
}

export function getPraticheStatusIcon(status: { [key: string]: boolean }, wintarExist?: boolean) {
    if (
        status &&
        status.chiuso &&
        status.scarto_wintar === false &&
        status.scarto_firme === false &&
        status.scarto_legacy === false &&
        !status.scarto_documentale &&
        !status.manual_check_required
    )
        return <Icon mr="2" boxSize="4" as={FcApproval} />;
    else if (
        (status &&
            status.scarto_wintar &&
            !status.scarto_firme &&
            !status.scarto_legacy &&
            !status.scarto_documentale &&
            !status.manual_check_required &&
            wintarExist === false) ||
        (status &&
            status.scarto_wintar === false &&
            status.scarto_firme === false &&
            status.scarto_legacy === false &&
            !status.scarto_documentale &&
            !status.manual_check_required) ||
        (status && status.scarto_wintar === null && status.scarto_firme === null && status.scarto_legacy === null)
    )
        return <Icon mr="2" boxSize="4" as={FcMediumPriority} />;
    else return <Icon mr="2" boxSize="4" as={FcHighPriority} />;
}

function getFascicoliStatus(status: { [key: string]: boolean }) {
    if (status) return <Icon mr="2" boxSize="4" as={FcHighPriority} />;
    else return <Icon mr="2" boxSize="4" as={FcApproval} />;
}

function getEmailStatusIcon(status: string) {
    if (status === 'ok') return <Icon mr="2" boxSize="4" as={FcApproval} />;
    else if (status === 'senza_allegati') return <Icon mr="2" boxSize="4" as={FcMediumPriority} />;
    else return <Icon mr="2" boxSize="4" as={FcHighPriority} />;
}

export function Table({ type }: TableProps) {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{ [key: string]: any }>({});
    const [filters, setFilters] = useState<{ [key: string]: any }>([]);
    const [dateRange, setDateRange] = useState<Array<any>>([new Date(), new Date()]);
    const [rowForPage, setRowForPage] = useState(20);
    const [actualPage, setActualPage] = useState(1);

    const urlParams: any = new URLSearchParams(history.location.search);

    useEffect(() => {
        setData([]);
        setLoading(true);
        setRowForPage(urlParams.get('limit') || 20);
        setDateRange([new Date(), new Date()]);
        if (urlParams.get('skip')) setActualPage(urlParams.get('skip') / (urlParams.get('limit') || 20) + 1);
        const filters = Object.fromEntries(urlParams);
        const order: any = {};
        const orderByStorage = localStorage.getItem('order_by');
        if (orderByStorage) {
            const orderArray = orderByStorage.split(':');
            if (orderArray && orderArray.length > 0) {
                order.order_by = orderArray[0];
                order.order = orderArray[1];
            }
        }
        if (urlParams.get('filter')) {
            delete filters.filter;
            filters.status = urlParams.get('filter');
        }
        setFilters({ limit: urlParams.get('limit') || 20, ...filters, ...order });

        getAll(type, { params: { limit: urlParams.get('limit') || 20, ...filters, ...order } }).then((data) => {
            setData(data);
            setLoading(false);
        });
    }, [type, urlParams.toString()]);

    function getTitle() {
        return type.charAt(0).toUpperCase() + type.slice(1);
    }

    function changeColumnSort(type: string, value: string) {
        const sortArray = [undefined, 1, -1];
        const actualSorting = filters.order;
        const newColumnSorting: { [key: string]: any } = {};
        newColumnSorting[value] = sortArray[sortArray.indexOf(actualSorting) + (1 % sortArray.length)];
        let newFilters: { [key: string]: any } = { ...filters };
        if (newColumnSorting[value]) {
            newFilters = { ...filters, order_by: value, order: newColumnSorting[value] };
            setFilters(newFilters);
        } else {
            delete newFilters.order_by;
            delete newFilters.order;
            setFilters(newFilters);
        }
        if (newFilters.order_by)
            localStorage.setItem('order_by', newFilters.order_by.concat(':').concat(newFilters.order));
        else localStorage.removeItem('order_by');
        getAll(type, { params: newFilters }).then((data) => {
            setData(data);
            setLoading(false);
        });
    }

    function handleFilterEvent(field: string, value: any, isDateRange?: boolean) {
        const baseFilter = { limit: 20, skip: 0 };
        if (isDateRange) {
            setDateRange(value);
            const newDateFilter: { [key: string]: any } = {};
            newDateFilter.date_from = getDateFormatted(value[0], false);
            if (value.length > 1 && value[1]) newDateFilter.date_to = getDateFormatted(value[1], false);
            setFilters({ ...filters, ...newDateFilter, ...baseFilter });
        } else {
            setFilters({ ...filters, [field]: value, ...baseFilter });
        }
    }

    function resetFilters() {
        setFilters({ limit: 20 });
        setDateRange([new Date(), new Date()]);
        setActualPage(1);
        history.push({
            pathname: `/${type}`,
        });
    }

    function getFilters(type: string) {
        const formFilters: any = [];
        columns[type].map((column) => {
            if (column.filter !== undefined && !column.filter) return;
            switch (column.type) {
                case 'string':
                case 'number':
                    if (column.options)
                        formFilters.push(
                            <>
                                <FormControl id={column.value} w="90%" maxW="180px">
                                    <FormLabel>{column.label}</FormLabel>
                                    <Select
                                        size="sm"
                                        placeholder={column.label}
                                        onChange={(event) => handleFilterEvent(column.value, event.target.value)}
                                        value={filters[column.value] ? filters[column.value] : ''}
                                    >
                                        {column.options.split(',').map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Spacer />
                            </>,
                        );
                    else
                        formFilters.push(
                            <>
                                <FormControl id={column.value} w="90%" maxW="180px">
                                    <FormLabel>{column.label}</FormLabel>
                                    <Input
                                        size="sm"
                                        placeholder={column.label}
                                        onChange={(event) => handleFilterEvent(column.value, event.target.value)}
                                        value={filters[column.value] ? filters[column.value] : ''}
                                    />
                                </FormControl>
                                <Spacer />
                            </>,
                        );
                    break;
                case 'date':
                    formFilters.push(
                        <>
                            <FormControl id={column.value} w="90%" maxW="210px">
                                <FormLabel>{column.label}</FormLabel>
                                <DatePicker
                                    onChange={(selectedDateRanges) =>
                                        handleFilterEvent(column.value, selectedDateRanges, true)
                                    }
                                    selectedDate={dateRange[0]}
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                />
                            </FormControl>
                            <Spacer />
                        </>,
                    );
                    break;
            }
        });
        if (formFilters.length === 0) return null;
        return (
            <Flex mb={4} flexDir="column" p="4" bg="white">
                <Flex>
                    <HStack spacing="24px">{formFilters}</HStack>
                </Flex>
                <Flex mt="4" alignItems="center">
                    <Button
                        onClick={() =>
                            history.push({
                                pathname: `/${type}`,
                                search: new URLSearchParams(filters).toString(),
                            })
                        }
                        colorScheme="blue"
                        size="sm"
                    >
                        Applica
                    </Button>
                    <Button onClick={resetFilters} colorScheme="blue" size="sm" ml="4" variant="link">
                        Reset
                    </Button>
                </Flex>
            </Flex>
        );
    }

    function changeLimit(value: number) {
        const newFilters: { [key: string]: any } = { ...filters, limit: value, skip: 0 };
        setFilters(newFilters);
        setRowForPage(value);
        setActualPage(1);
        history.push({
            pathname: `/${type}`,
            search: new URLSearchParams(newFilters).toString(),
        });
    }

    function onPageChange(value: number) {
        const newFilters: { [key: string]: any } = { ...filters, limit: rowForPage, skip: (value - 1) * rowForPage };
        setFilters(newFilters);
        setActualPage(value);
        history.push({
            pathname: `/${type}`,
            search: new URLSearchParams(newFilters).toString(),
        });
    }

    return (
        <Flex flexDir="column">
            {type && (
                <Loader isLoading={loading}>
                    <Heading size="xl" mb="8" pl="4">
                        {getTitle()}
                    </Heading>
                    {getFilters(type)}
                    <BodyTable
                        columns={columns[type]}
                        data={data[type]}
                        changeColumnSort={(value: string) => changeColumnSort(type, value)}
                        filters={filters}
                        onDoubleClick={(id) => history.push(`/${type}/${id}`)}
                    />
                    <Flex bottom="0" w={['100%', 'calc(100vw - 240px)']} ml={['0', '-16px']}>
                        <Pagination
                            total={data?.total}
                            rowForPage={rowForPage}
                            actualPage={actualPage}
                            onLimitChange={changeLimit}
                            onPageChange={onPageChange}
                        />
                    </Flex>
                </Loader>
            )}
        </Flex>
    );
}
