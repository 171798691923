import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
// import { getLocalStorage } from 'ds4biz-core';
import { ExtendedAxiosConfig } from './crud';

// const localStorage = getLocalStorage('loko');

// RESPONSE (response, instance)
function successHandler(
    response: AxiosResponse & { config: AxiosRequestConfig & ExtendedAxiosConfig },
): AxiosResponse | any {
    const allResponse = response.config.full;

    return allResponse ? response : response.data;
}

// (error, instance)
function errorHandler(
    error: AxiosError & { config: AxiosRequestConfig & ExtendedAxiosConfig },
): Promise<{}> | undefined {
    if (!error?.response) {
        return;
    }

    // ARRAY CONTENENTE I STATUS CODE DA SKIPPARE PER LA SNACKBAR DI ERRORE
    // const skippedCodes = error.config?.skippedCodes || [];

    // const { status, data } = error.response || {};

    // if (!skippedCodes.includes(status)) {
    // const errorMessage = data?.error ? data?.error : 'Some error occurred, try again';
    // store.dispatch({
    //     type: 'messages/showNotification',
    //     payload: {
    //         title: errorMessage,
    //         type: 'error',
    //     },
    // });
    // }

    return Promise.reject({ ...error });
}
// END RESPONSE

export { successHandler, errorHandler };
