import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Box, Heading } from '@chakra-ui/layout';
import _ from 'lodash';
import { Document, IDocument } from 'components/shared/Document';
import { getDateFormatted } from '../../components/shared/Table';

interface Props {
    data: { [key: string]: any };
}

export function Email({ data = {}, ...props }: Props) {
    function createMarkup(part: any) {
        return { __html: part };
    }
    const documentByName: { [key: string]: IDocument[] } = _.groupBy(data?.documenti, 'nome_file');

    console.log(data?.body_html);

    return (
        <>
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Testo</Heading>
                    </Box>
                    <Box mr="4" fontSize="xs" color="gray.600">
                        {getDateFormatted(data.data, true)}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {data?.body_html && data.body_html.length > 0 ? (
                        <Box dangerouslySetInnerHTML={createMarkup(data?.body_html)} />
                    ) : (
                        <Box>{data.body}</Box>
                    )}
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Allegati</Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {Object.keys(documentByName).map((fileName: string) => {
                        return (
                            <Box
                                key={fileName}
                                p="0.6rem"
                                mb="0.5rem"
                                border="1px solid"
                                borderColor="gray.500"
                                borderRadius="0.4rem"
                            >
                                {
                                    <Heading as="h2" size="sm">
                                        {fileName?.replaceAll('##', '.')}
                                    </Heading>
                                }
                                {documentByName[fileName].map((document: any, index: number) => (
                                    <Document key={index} doc={document as IDocument} />
                                ))}
                            </Box>
                        );
                    })}
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}
