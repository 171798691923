import { useContext } from 'react';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { Box, Heading } from '@chakra-ui/layout';
import {
    createStandaloneToast,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Bacheca } from 'components/shared/Bacheca';
import { Pratica } from 'components/shared/Pratica';
import { AppContext, IContext } from '../../contexts/AppContext';
import { authProvider } from '../../services/auth';
import { deleteOne, updateOne } from '../../services/crud';

interface Props {
    data: { [key: string]: any };
    setIsLoading: any;
    reloadData: () => any;
}

export function Fascicolo({ data = {}, setIsLoading, ...props }: Props) {
    const history = useHistory();
    const toast = useToast();
    const { pratica, showFascicoloDeleteWarningModal, setShowFascicoloDeleteWarningModal } = useContext(
        AppContext,
    ) as IContext;

    function movePratica(pratica: any | null) {
        setIsLoading(true);
        if (!pratica)
            return toast({
                title: 'Nessuna pratica selezionata',
                status: 'warning',
                duration: 9000,
                isClosable: true,
            });
        updateOne('fascicoli', pratica._id, {
            fascicolo_id: data._id,
            codice_utente: authProvider?.getAccount()?.userName,
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Pratica '.concat(pratica._id).concat(' spostata con successo'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function deleteFascicolo() {
        const toast = createStandaloneToast();
        setIsLoading(true);
        deleteOne('fascicoli', data._id)
            .then((response) => {
                setIsLoading(false);
                history.push('/fascicoli');
                return toast({
                    title: 'Fascicolo eliminato correttamente',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: `Errore imprevisto durante l'eliminazione del fascicolo`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    return (
        <>
            <Modal
                isOpen={!!showFascicoloDeleteWarningModal}
                onClose={() => {
                    setShowFascicoloDeleteWarningModal(false);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Elimina fascicolo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ alignSelf: 'center' }}>Sei sicuro di voler eliminare il fascicolo?</ModalBody>
                    <ModalFooter>
                        <Button
                            variant="ghost"
                            mr={3}
                            onClick={() => {
                                setShowFascicoloDeleteWarningModal(false);
                            }}
                        >
                            Annulla
                        </Button>
                        <Button colorScheme="blue" onClick={deleteFascicolo}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Pratiche associate</Heading>
                    </Box>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            movePratica(pratica);
                        }}
                        variant="ghost"
                        colorScheme="blue"
                        size="sm"
                        mr="4"
                    >
                        Sposta qui la Pratica che hai selezionato
                    </Button>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {data?.pratiche?.map((pratica: any, index: number) => (
                        <Pratica key={index} pratica={pratica} />
                    ))}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Bacheca</Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Bacheca messages={data.note} reloadData={props.reloadData} id={data._id} type={'fascicoli'} />
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}
