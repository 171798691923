import { Fragment, ReactNode, useEffect, useState } from 'react';
import {
    useDisclosure,
    Image,
    Box,
    Flex,
    Icon,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    IconButton,
    Collapse,
    As,
    FlexProps,
    BoxProps,
    Text,
} from '@chakra-ui/react';
import { FcStatistics, FcDocument, FcFolder, FcDatabase, FcInspection, FcInvite } from 'react-icons/fc';
import { MdKeyboardArrowRight, MdLogout } from 'react-icons/md';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { getAll } from '../../services/crud';

interface Item {
    label: string;
    value: string;
    icon?: any;
    onClick?: (props: Props) => any;
    children?: Item[];
    open?: boolean;
    count_key?: string;
}

const items: Item[] = [
    {
        label: 'Statistiche V2',
        value: '/dashboard_statistiche',
        icon: FcStatistics,
    },
    {
        label: 'Statistiche',
        value: '/dashboard',
        icon: FcStatistics,
    },
    {
        label: 'Email',
        value: '/email',
        icon: FcInvite,
        children: [
            {
                label: 'Allegato orfano',
                value: 'allegato_orfano',
            },
            {
                label: 'Senza allegati',
                value: 'senza_allegati',
            },
            {
                label: 'Scarto non riconciliato',
                value: 'scarto_non_riconciliato',
            },
        ],
        open: false,
    },
    {
        label: 'Pratiche',
        value: '/pratiche',
        count_key: 'total',
        icon: FcFolder,
        children: [
            {
                label: 'Da lavorare',
                value: 'scarto_firme,scarto_legacy,scarto_wintar,scarto_documentale,scarto_AUI',
                count_key: 'to_work',
                children: [
                    {
                        label: 'Scarto firme',
                        value: 'scarto_firme',
                    },
                    {
                        label: 'Scarto legacy',
                        value: 'scarto_legacy',
                    },
                    {
                        label: 'Scarto Wintar',
                        value: 'scarto_wintar',
                    },
                    {
                        label: 'Scarto Documentale',
                        value: 'scarto_documentale',
                    },
                    {
                        label: 'Scarto AUI',
                        value: 'scarto_AUI',
                    },
                ],
            },
            {
                label: 'In transito e ghost',
                value: 'in_transito_e_ghost',
                count_key: 'in_transito_e_ghost_count',
                children: [],
            },
            {
                label: 'In attesa di soluzione',
                value: 'pending_request',
                count_key: 'waiting',
                children: [],
            },
            {
                label: 'Chiuse',
                value: 'chiuso',
                count_key: 'closed',
                children: [],
            },
            {
                label: 'Chiuse automaticamente',
                value: 'chiuso_automaticamente',
                count_key: 'chiuso_automaticamente',
                children: [],
            },
            {
                label: 'Gestione manuale',
                value: 'manual',
                count_key: 'manual',
                children: [],
            },
        ],
        open: false,
    },
    /* {
        label: 'Fascicoli',
        value: '/fascicoli',
        icon: FcDatabase,
        // children: [
        //     {
        //         label: 'Da sanare',
        //         value: 'fascicoli_da_sanare',
        //     },
        //     {
        //         label: 'In attesa di altra Pratica',
        //         value: 'fascicoli_in_attesa_pratica',
        //     },
        //     {
        //         label: 'In scadenza',
        //         value: 'fascicoli_in_scadenza',
        //     },
        //     {
        //         label: 'Chiusi',
        //         value: 'fascicoli_chiusi',
        //     },
        // ],
        open: false,
    }, */
    {
        label: 'Autoesitati',
        value: '/autoesitati',
        icon: FcInspection,
    },
    {
        label: 'Logout',
        value: '/',
        onClick: (props) => props.onLogout(),
        icon: MdLogout,
    },
];

const NavItem = (props: { icon?: As<any>; children: ReactNode; to?: string; isChild?: boolean } & FlexProps) => {
    const { icon, children, ...rest } = props;
    return (
        <Flex
            align="center"
            px="4"
            pl="4"
            py="3"
            cursor="pointer"
            color="inherit"
            _hover={{
                bg: 'gray.100',
                color: 'gray.900',
            }}
            role="group"
            fontWeight="semibold"
            transition=".15s ease"
            as={Link}
            {...rest}
        >
            {icon && (
                <Icon
                    mr="2"
                    boxSize="4"
                    _groupHover={{
                        color: 'gray.600',
                    }}
                    as={icon}
                />
            )}
            {children}
        </Flex>
    );
};

const SidebarContent = ({ ...props }: Props) => {
    const [menuItems, setmenuItems] = useState(items);
    const [dataCount, setDataCount] = useState<any>([]);

    const types = ['email', 'fascicoli'];
    useEffect(() => {
        const callApiCount = () => {
            const promises = [];
            for (const type of types) {
                promises.push(getAll(type, { params: { limit: 1 } }));
            }
            promises.push(getAll('pratiche/count'));
            Promise.all(promises).then((responses) => setDataCount(responses));
        };
        callApiCount();
        const refreshCount = setInterval(callApiCount, 120000); //2 min.
        return () => clearInterval(refreshCount);
    }, []);

    function handleArrowClick(item: Item) {
        const updatedMenuItems = [...menuItems];
        const index = menuItems.findIndex((_item) => _item.value === item.value);
        updatedMenuItems[index].open = !updatedMenuItems[index].open;

        setmenuItems(updatedMenuItems);
    }

    return (
        <Box
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg="white"
            borderColor="inherit"
            borderRightWidth="1px"
            w="60"
            {...props}
        >
            <Flex px="4" py="5" flexDir="column">
                <Image src={logo} w="90px" />
            </Flex>
            <Flex direction="column" as="nav" fontSize="sm" color="gray.600" aria-label="Main Navigation">
                {menuItems.map((item) => {
                    let badgeCount;
                    const elementCount = dataCount?.find((data: any) => data[item.label.toLowerCase()])?.full;
                    const countKey = dataCount?.find((data: any) => item.count_key && data.closed !== undefined);
                    if (countKey) {
                        badgeCount = <Text>{countKey[item.count_key]}</Text>;
                    } else if (elementCount) {
                        badgeCount = <Text>{elementCount}</Text>;
                    }
                    return (
                        <Fragment key={item.value}>
                            <NavItem
                                icon={item.icon}
                                as={Link}
                                to={item.value}
                                onClick={() => (item.onClick ? item.onClick(props) : null)}
                            >
                                <Flex alignItems={'center'} flex="1">
                                    <Box>{item.label}</Box>
                                    <Box ml="auto" mr="0.4rem" fontWeight="700" fontSize="0.8rem">
                                        {badgeCount}
                                    </Box>
                                </Flex>
                                {item.children && (
                                    <IconButton
                                        size="xs"
                                        color="black"
                                        isRound
                                        variant="solid"
                                        aria-label="Expand item"
                                        icon={<MdKeyboardArrowRight />}
                                        ml="auto"
                                        transform={item.open ? 'rotate(90deg)' : 'none'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            handleArrowClick(item);
                                        }}
                                    />
                                )}
                            </NavItem>

                            <Collapse in={item.open} unmountOnExit>
                                {item.children &&
                                    item.children.map((child) => {
                                        if (child.children) {
                                            const countObject = dataCount?.find(
                                                (data: any) => child.count_key && data[child.count_key] !== undefined,
                                            );
                                            return (
                                                <>
                                                    <NavItem
                                                        key={child.value}
                                                        pl="10"
                                                        py="2"
                                                        fontWeight="bold"
                                                        to={
                                                            child.value
                                                                ? item.value.concat('?filter=').concat(child.value)
                                                                : item.value
                                                        }
                                                    >
                                                        {child.label}

                                                        {countObject && child.count_key && (
                                                            <Box
                                                                ml="auto"
                                                                mr="0.6rem"
                                                                fontWeight="700"
                                                                fontSize="0.8rem"
                                                            >
                                                                {countObject[child.count_key]}
                                                            </Box>
                                                        )}
                                                    </NavItem>
                                                    {child?.children?.map((child) => (
                                                        <NavItem
                                                            key={child.value}
                                                            pl="12"
                                                            py="2"
                                                            fontWeight="regular"
                                                            to={item.value.concat('?filter=').concat(child.value)}
                                                        >
                                                            {child.label}
                                                        </NavItem>
                                                    ))}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <NavItem
                                                    key={child.value}
                                                    pl="10"
                                                    py="2"
                                                    fontWeight="regular"
                                                    to={item.value.concat('?filter=').concat(child.value)}
                                                >
                                                    {child.label}
                                                </NavItem>
                                            );
                                        }
                                    })}
                            </Collapse>
                        </Fragment>
                    );
                })}
            </Flex>
        </Box>
    );
};

interface Props extends BoxProps {
    onLogout?: any;
}

export function Sidebar({ onLogout }: Props) {
    const sidebar = useDisclosure();

    return (
        <>
            <SidebarContent display={{ base: 'none', md: 'unset' }} onLogout={onLogout} />
            <Drawer isOpen={sidebar.isOpen} onClose={sidebar.onClose} placement="left">
                <DrawerOverlay />
                <DrawerContent>
                    <SidebarContent w="full" borderRight="none" onLogout={onLogout} />
                </DrawerContent>
            </Drawer>
        </>
    );
}
