import { extendTheme } from '@chakra-ui/react';

const config = {
    useSystemColorMode: false,
    initialColorMode: 'light',
};

const overrides = { config };

export const customTheme = extendTheme(overrides);
