import { useEffect, useState } from 'react';
import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/layout';
import { Button, FormControl, FormLabel, HStack, IconButton, Select, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { Td } from '@chakra-ui/table';
import { FileName } from 'components/shared/React-Doc-Viewer/components/FileName';
import DatePicker from '../../components/shared/DatePicker/date-picker';
import { Loader } from '../../components/shared/Loader';
import { getDateFormatted } from '../../components/shared/Table';
import { getAll } from '../../services/crud';
import { DashboardBox } from './DashboardBox';

export function DashboardStatistiche() {
    const [dateRange, setDateRange] = useState<Array<any>>([undefined, undefined]);
    const [defaultDateRange, setDefaultDateRange] = useState<Array<any>>([undefined, undefined]);
    const [company, setCompany] = useState<string | undefined>(undefined);
    const [channel, setChannel] = useState<string | undefined>(undefined);
    const [micStats, setMicStats] = useState<any>([]);

    const [lastDateRange, setLastDateRange] = useState<Array<any>>([undefined, undefined]);
    const [lastCompany, setLastCompany] = useState<string | undefined>(undefined);
    const [lastChannel, setLastChannel] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [praticheTitle, setPraticheTitle] = useState<string | undefined>('Pratiche');

    function setLastWeek() {
        const currentDate = new Date();
        const dayNumber = currentDate.getDay();
        const daysToSub = [6 + dayNumber, dayNumber];

        const dateFrom = new Date().setDate(currentDate.getDate() - daysToSub[0]);
        const dateTo = new Date().setDate(currentDate.getDate() - daysToSub[1]);

        setDefaultDateRange([dateFrom, dateTo]);
    }

    function getData() {
        setLoading(true);

        const micParams: any = {};
        if (dateRange[0]) micParams.date_from = getDateFormatted(dateRange[0], false);
        if (dateRange[1]) micParams.date_to = getDateFormatted(dateRange[1], false);

        if (channel) {
            micParams.canale_documentale = channel === 'documentale' || channel === 'all';
            micParams.canale_email = channel === 'email' || channel === 'all';
        }

        if (company) micParams.compagnia = company;

        console.log(micParams);
        getAll('/stats/pratiche', { params: { ...micParams } })
            .then((response) => {
                setLoading(false);
                setMicStats([response]);
                //set last search for file download
                setLastDateRange(dateRange);
                setLastChannel(channel);
                setLastCompany(company);
                if (channel === 'all') {
                    setPraticheTitle(`Pratiche ${company.toUpperCase()}`);
                } else {
                    setPraticheTitle(`Pratiche ${channel} ${company.toUpperCase()}`);
                }
            })
            .catch(() => setLoading(false));
    }

    function getFileName(value: string) {
        const fileChannel = lastChannel === 'all' ? '' : lastChannel;
        return `${value}_${fileChannel}_${lastCompany || ''}${getDateFormatted(
            lastDateRange[0],
            false,
        )}-${getDateFormatted(lastDateRange[1], false)}.xlsx`;
    }

    function downloadFile(value: string) {
        setLoading(true);

        getAll(`/stats/download/${value}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', getFileName(value));
                //link.setAttribute('download', `${value}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }

    function downloadQualitativeStats() {
        setLoading(true);

        getAll(`/stats/download/qualitative`, { responseType: 'blob' })
            .then((response) => {
                console.log(response);

                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                const currentDate = new Date();
                const filename = `statistiche_qualitative_${getDateFormatted(currentDate, false)}.xlsx`;
                link.setAttribute('download', filename);
                //link.setAttribute('download', `${value}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }

    useEffect(() => {
        setLastWeek();
    }, []);

    return (
        <Loader isLoading={loading}>
            <Box>
                <Box>
                    <Flex>
                        <HStack spacing="24px" width="75%" mb="30px" alignItems="end">
                            <FormControl id={'date1'} w="100%" isRequired>
                                <FormLabel>Data</FormLabel>
                                <DatePicker
                                    onChange={(selectedDate) => {
                                        setDateRange(selectedDate);
                                    }}
                                    selectedDate={dateRange[0]}
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                    placeholder={'Inserisci data'}
                                />
                            </FormControl>
                            <Tooltip label="Ultima settimana" bg="white.600" color="#3182ce" placement="bottom">
                                <IconButton
                                    aria-label="Last week selector received"
                                    boxSize={8}
                                    //variant="outline"
                                    color="#3182ce"
                                    icon={<CalendarIcon />}
                                    onClick={(e) => setDateRange(defaultDateRange)}
                                />
                            </Tooltip>
                            <Spacer />
                            <FormControl id="canale" w="90%" maxW="220px" isRequired>
                                <FormLabel>Canale</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Seleziona canale ricezione"
                                    onChange={(event) => setChannel(event.target.value)}
                                    value={channel}
                                >
                                    <option key="email" value="email">
                                        Email
                                    </option>
                                    <option key="documentale" value="documentale">
                                        Documentale
                                    </option>
                                    <option key="all" value="all">
                                        Tutti
                                    </option>
                                </Select>
                            </FormControl>
                            <Spacer />
                            <FormControl id="compagnia" w="90%" maxW="180px">
                                <FormLabel>Compagnia</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Seleziona compagnia"
                                    onChange={(event) => setCompany(event.target.value)}
                                    value={company}
                                >
                                    <option key="ITA" value="ITA">
                                        ITA
                                    </option>
                                    <option key="RMA" value="RMA">
                                        RMA
                                    </option>
                                </Select>
                            </FormControl>
                            <FormControl id="control-buttons" alignSelf="end">
                                <Button
                                    onClick={getData}
                                    colorScheme="blue"
                                    size="sm"
                                    isDisabled={!dateRange[0] || !dateRange[1] || !channel}
                                >
                                    Applica
                                </Button>
                                <Button
                                    onClick={() => {
                                        setDateRange([undefined, undefined]);
                                        setCompany('');
                                        setChannel('');
                                        setPraticheTitle('Pratiche');
                                        setLoading(false);
                                        setMicStats([]);
                                    }}
                                    colorScheme="blue"
                                    ml="5"
                                    size="sm"
                                    variant="link"
                                >
                                    Reset
                                </Button>
                            </FormControl>
                        </HStack>
                    </Flex>
                </Box>
                <HStack spacing="20px" pt={'10px'} alignItems="end">
                    <Box>
                        <DashboardBox
                            id="micTable"
                            bgColor="gray.300"
                            height="180px"
                            //title="PRATICHE"
                            title={praticheTitle}
                            onClickQualitative={downloadQualitativeStats}
                            columns={[
                                {
                                    label: 'Pervenuto',
                                    value: 'pervenuto',
                                    type: 'number',
                                    download: false,
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },

                                {
                                    label: 'Lavorato',
                                    value: 'lavorato',
                                    type: 'number',
                                    download: true,
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Manuale',
                                    value: 'manuale',
                                    type: 'number',
                                    download: true,
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                            ]}
                            data={micStats}
                            buttons={{
                                download: {
                                    onClick: (value) => downloadFile(value),
                                    isDisabled: micStats.length === 0,
                                },
                            }}
                        />
                    </Box>
                </HStack>
            </Box>
        </Loader>
    );
}
