import { Box, Flex } from '@chakra-ui/layout';

export interface IMessage {
    id: string;
    author: string;
    date: string;
    text: string;
    pratica: string;
}

export function Message({ message }: { message: IMessage }) {
    return (
        <Flex mb="2" p="4" border="1px solid" bg="gray.100" borderColor="transparent" borderRadius="xl">
            <Flex justifyContent="space-between" w="full">
                <Box>
                    <Box>
                        <b>{message.author}</b> ha scritto:
                    </Box>
                    <Box fontWeight="bold">{message.text}</Box>
                </Box>
                <Box fontSize="sm" color="gray.500" sx={{ whiteSpace: 'nowrap' }}>
                    {message.date}
                </Box>
            </Flex>
        </Flex>
    );
}
