import { AxiosRequestConfig, AxiosResponse } from 'axios';
import urljoin from 'url-join';
import { http } from 'services/api';
import { preProdId, prodId } from './auth';

export interface ExtendedAxiosConfig {
    full?: boolean;
    skippedCodes?: number[];
}

const oauthConfig = () => {
    const tokenKey = 'msal.'.concat(process.env.REACT_APP_ENV === 'PROD' ? prodId : preProdId).concat('.idtoken');
    return { headers: { Authorization: `${localStorage.getItem(tokenKey)}` } };
};

function getAll(resource: string, config?: AxiosRequestConfig): Promise<any | AxiosResponse> {
    return http.get(resource, { ...oauthConfig(), ...config });
}

function getOne(
    resource: string,
    id: string,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.get(urljoin(resource, id), { ...oauthConfig(), ...config });
}

function createOne(
    resource: string,
    payload: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.post(resource, payload, { ...oauthConfig(), ...config });
}

function updateOne(
    resource: string,
    id: string,
    payload: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.patch(urljoin(resource, id), payload, { ...oauthConfig(), ...config });
}

function overwriteOne(
    resource: string,
    id: string,
    payload: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.put(urljoin(resource, id), payload, { ...oauthConfig(), ...config });
}

function deleteOne(
    resource: string,
    id: string,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.delete(urljoin(resource, id), { ...oauthConfig(), ...config });
}

export { getAll, getOne, createOne, overwriteOne, updateOne, deleteOne };
