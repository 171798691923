import React, { HTMLAttributes } from 'react';
import { useColorMode } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

interface Props {
    isClearable?: boolean;
    onChange: (dates: any) => any;
    selectedDate: any;
    startDate: Date | undefined;
    endDate?: Date | undefined;
    showPopperArrow?: boolean;
    isRange?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

const DatePicker = ({
    selectedDate,
    onChange,
    isClearable = false,
    showPopperArrow = false,
    startDate,
    endDate,
    isRange = true,
    disabled = false,
    placeholder = 'Inserisci data',
}: Props & HTMLAttributes<HTMLElement>) => {
    const isLight = useColorMode().colorMode === 'light'; //you can check what theme you are using right now however you want

    return (
        // if you don't want to use chakra's colors or you just wwant to use the original ones,
        // set className to "light-theme-original" ↓↓↓↓
        <div className={isLight ? 'light-theme' : 'dark-theme'}>
            <ReactDatePicker
                disabled={disabled}
                selected={selectedDate}
                dateFormat={'dd/MM/yyyy'}
                onChange={onChange}
                maxDate={new Date()}
                isClearable={isClearable}
                startDate={startDate}
                endDate={endDate}
                showPopperArrow={showPopperArrow}
                selectsRange={isRange}
                placeholderText={placeholder}
                className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
            />
        </div>
    );
};

export default DatePicker;
