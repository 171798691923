import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Box, Heading } from '@chakra-ui/layout';
import { Document, IDocument } from 'components/shared/Document';

interface Props {
    data: IDocument | any;
}

export function Documento({
    data = {
        _id: '',
        nome_file: '',
        dimensione: '',
        email: '',
        pratica: '',
    },
    ...props
}: Props) {
    return (
        <>
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">File</Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Document doc={data} />
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}
