import { Flex, Heading } from '@chakra-ui/layout';
import { Box, Button, Container, Grid } from '@chakra-ui/react';
import azure from 'assets/images/azure.png';
import background from 'assets/images/landing-page-background.png';
import logo from 'assets/images/logo.png';

interface Props {
    onLogin: any;
}

export function Login({ onLogin }: Props) {
    return (
        <Box>
            <Flex bgColor="white" minHeight="100px" alignItems="center" justifyContent="flex-start">
                <Container id="container" maxWidth="60rem">
                    <Grid>
                        <img src={logo} alt="Reale Mutua logo" width="170" height="42" />
                    </Grid>
                </Container>
            </Flex>
            <Box
                id="content"
                backgroundImage={background}
                backgroundPosition="top center"
                backgroundRepeat="no-repeat"
                backgroundSize="100%"
            >
                <Container id="form_container" fixed>
                    <Grid container direction="row" justify="center">
                        <Box my={4} pb={8} position="relative" backgroundColor="white" boxShadow="base">
                            <Flex
                                square
                                minHeight="50vh"
                                padding="2rem 2rem 4rem"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Box my={4}>
                                    <Heading as="h4" size="md">
                                        Accedi con Azure
                                    </Heading>
                                </Box>
                                <Button variant="solid" colorScheme="blue" size="lg" onClick={onLogin}>
                                    <img src={azure} style={{ padding: '3rem' }} height="30px" />
                                </Button>
                            </Flex>
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
