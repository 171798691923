import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export function Main({ children }: { children: ReactNode }) {
    return (
        <Box as="section" bg="gray.50" minH="100vh">
            <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
                <Box as="main" p="4">
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
