import { createContext, ReactNode, useState } from 'react';
import { IDocument } from '../components/shared/Document';

export interface IContext {
    document: IDocument | null;
    setDocument: (document: IDocument) => void;
    pratica: any | null;
    setPratica: (pratica: any) => void;
    praticaManualModalWarningData: any;
    setPraticaManualModalWarningData: (data: any) => void;
    showDocumentaleModal: any;
    setShowDocumentaleModal: (data: any) => void;
    showFascicoloDeleteWarningModal: any;
    setShowFascicoloDeleteWarningModal: (data: any) => void;
}

export const AppContext = createContext<IContext | null>(null);

const AppProvider = ({ children }: { children: ReactNode }) => {
    const [document, setDocument] = useState<IDocument | null>(null);
    const [pratica, setPratica] = useState<any | null>(null);
    const [praticaManualModalWarningData, setPraticaManualModalWarningData] = useState<any | null>(null);
    const [showFascicoloDeleteWarningModal, setShowFascicoloDeleteWarningModal] = useState<boolean>(false);
    const [showDocumentaleModal, setShowDocumentaleModal] = useState<boolean>(false);

    return (
        <AppContext.Provider
            value={{
                document,
                setDocument,
                pratica,
                setPratica,
                praticaManualModalWarningData,
                setPraticaManualModalWarningData,
                showDocumentaleModal,
                setShowDocumentaleModal,
                showFascicoloDeleteWarningModal,
                setShowFascicoloDeleteWarningModal,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
