import { useState } from 'react';
import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { Textarea, useToast } from '@chakra-ui/react';
import { authProvider } from '../../services/auth';
import { overwriteOne } from '../../services/crud';

interface Props {
    reloadData: any;
    id: any;
    type: string;
}

export function NewMessagePlaceholder({ type = '', ...props }: Props) {
    const [note, setNote] = useState('');
    const toast = useToast();

    function sendNote() {
        const noteToSend = {
            author: authProvider?.getAccount()?.userName,
            text: note,
        };
        overwriteOne(type.concat('/note'), props.id, noteToSend).then(() => {
            setNote('');
            props.reloadData();
        });
    }

    const handleInputChange = (e: any) => {
        const inputValue = e.target.value;
        setNote(inputValue);
    };
    return (
        <Box>
            <Textarea value={note} onChange={handleInputChange} placeholder="Inserisci nota" size="sm" />
            <Button mt={2} onClick={sendNote} colorScheme="blue" size="sm">
                Aggiungi Nota
            </Button>
        </Box>
    );
}
