import { useContext, useEffect, useRef, useState } from 'react';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { AddIcon, InfoIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, SimpleGrid } from '@chakra-ui/layout';
import {
    useToast,
    Checkbox,
    Td,
    Input,
    Tooltip,
    Text,
    HStack,
    Tag,
    TagLabel,
    TagLeftIcon,
    VStack,
    Textarea,
    createStandaloneToast,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Modal,
    Select,
    Switch,
} from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import { FcApproval, FcHighPriority, FcMediumPriority } from 'react-icons/fc';
import { Bacheca } from 'components/shared/Bacheca';
import { Document, IDocument } from 'components/shared/Document';
import DatePicker from '../../components/shared/DatePicker/date-picker';
import { EmailChipInput } from '../../components/shared/EmailChipInput';
import { EmailTread } from '../../components/shared/EmailTread';
import { getDateFormatted } from '../../components/shared/Table';
import { BodyTable } from '../../components/shared/Table/BodyTable';
import { AppContext, IContext } from '../../contexts/AppContext';
import { authProvider } from '../../services/auth';
import { createOne, overwriteOne, updateOne } from '../../services/crud';
import { matchPattern } from '../../utils';

interface Props {
    data: { [key: string]: any };
    reloadData: () => any;
    setIsLoading: any;
    setLoaderMessage: any;
}

moment.locale('it');

const sectionNameLabel: any = {
    info_documento: 'Info documento',
    sezione_1: 'Sezione 1',
    sezione_2: 'Sezione 2',
    sezione_3: 'Sezione 3',
    info_pagamento: 'Info pagamento',
};

export function Pratica({ data = {}, setIsLoading, setLoaderMessage, ...props }: Props) {
    const {
        document,
        praticaManualModalWarningData,
        setPraticaManualModalWarningData,
        showDocumentaleModal,
        setShowDocumentaleModal,
    } = useContext(AppContext) as IContext;
    const inputRef = useRef<any>(null);
    const toast = useToast();

    const checkLegacyColumns = [
        { value: 'entity_label', keyField: true, notShow: true },
        {
            label: '',
            value: 'entity_label',
            render: (key: string, value: any) => (
                <Td key={key} maxW="8rem" minW={'8rem'}>
                    {value}
                </Td>
            ),
        },
        {
            label: 'Extracted',
            value: 'extracted_value',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td key={key} maxW="8rem" minW={'8rem'}>
                    {getCheckBoxAuiSelection(entityLabel, value, key, 'extracted_value', sectionName)}
                </Td>
            ),
        },
        {
            label: 'Legacy',
            value: 'legacy_value',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td minW={'8rem'} maxW="8rem" key={key}>
                    {getCheckBoxAuiSelection(entityLabel, value, key, 'legacy_value', sectionName)}
                </Td>
            ),
        },
        {
            label: 'Wintar',
            value: 'wintar_value',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td key={key} maxW="8rem" minW={'8rem'}>
                    {getCheckBoxAuiSelection(entityLabel, value, key, 'wintar_value', sectionName)}
                </Td>
            ),
        },
        {
            label: 'Validazione',
            value: 'valid',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td key={key.concat('Aui')} minW={'9rem'} maxW="9rem">
                    {getAuiInput(entityLabel, sectionName)}
                </Td>
            ),
        },
        {
            label: '',
            value: 'valid_status',
            render: (key: string, value: any) => (
                <Td key={key.concat('Status')} textAlign="right">
                    {getStatusIcon(value)}
                </Td>
            ),
        },
    ];

    const intermediarioColumns = [
        { value: 'entity_label', keyField: true, notShow: true },
        {
            label: '',
            value: 'entity_label',
            render: (key: string, value: any) => (
                <Td key={key.concat('_intermediario')} maxW="8rem" minW={'8rem'}>
                    {value}
                </Td>
            ),
        },
        {
            label: '',
            value: 'blank',
            render: (key: string, value: any, entityLabel: string, sectionName: string, row, i) => (
                <Td key={i.toString().concat('_intermediario_placeholder1')} maxW="8rem" minW={'8rem'} />
            ),
        },
        {
            label: '',
            value: 'blank2',
            render: (key: string, value: any, entityLabel: string, sectionName: string, row, i) => (
                <Td key={i.toString().concat('_intermediario_placeholder2')} maxW="8rem" minW={'8rem'} />
            ),
        },
        {
            label: 'Wintar',
            value: 'wintar_value',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td key={key.concat('_intermediario2')} maxW="8rem" minW={'8rem'}>
                    {value}
                </Td>
            ),
        },
        {
            label: 'Validazione',
            value: 'valid',
            render: (key: string, value: any, entityLabel: string) => (
                <Td key={key.concat('intermediario3')} minW={'9rem'} maxW="9rem">
                    {getIntermediarioInput(entityLabel)}
                </Td>
            ),
        },
        {
            label: '',
            value: 'valid',
            render: (key: string, value: any) => (
                <Td key={key.concat('_intermediario4')} textAlign="right">
                    {<Icon mr="2" boxSize="4" as={FcApproval} />}
                </Td>
            ),
        },
    ];

    const connessioneOperazioneColumns = [
        { value: 'entity_name', keyField: true, notShow: true },
        {
            label: '',
            value: 'entity_label',
            render: (key: string, value: any) => (
                <Td key={key.concat('_connOp')} maxW="8rem" minW={'8rem'}>
                    {value}
                </Td>
            ),
        },
        {
            label: '',
            value: 'blank',
            render: (key: string, value: any, entityLabel: string, sectionName: string, row, i) => (
                <Td key={i.toString().concat('__connOp_placeholder1')} maxW="8rem" minW={'8rem'} />
            ),
        },
        {
            label: '',
            value: 'progressivo_button',
            render: (key: string, value: any, entityLabel: string, sectionName: string, row, i) => (
                <Td key={i.toString().concat('__connOp_placeholder2')} maxW="8rem" minW={'8rem'}>
                    {value && (
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={sendProgressivoTrue}
                            isDisabled={!data?.presa_in_carico}
                        >
                            Progressivo
                        </Button>
                    )}
                </Td>
            ),
        },
        {
            label: 'Wintar',
            value: 'wintar_value',
            render: (key: string, value: any, entityLabel: string, sectionName: string) => (
                <Td key={key.concat('__connOp')} maxW="8rem" minW={'8rem'}>
                    {value}
                </Td>
            ),
        },
        {
            label: 'Validazione',
            value: 'valid',
            render: (key: string, value: any, entityLabel: string) => (
                <Td key={key.concat('_connOp')} minW={'9rem'} maxW="9rem">
                    {getConnessioneOpearzioneInput(entityLabel)}
                </Td>
            ),
        },
        {
            label: '',
            value: 'valid',
            render: (key: string, value: any) => (
                <Td key={key.concat('_intermediario4')} textAlign="right">
                    {<Icon mr="2" boxSize="4" as={FcApproval} />}
                </Td>
            ),
        },
    ];

    function setSelection(entityLabel: any, sectionName: any, aui: any, value: any) {
        const newEstrazione = [...auiSelection];
        const index = auiSelection.findIndex((aui) => aui.entity_label === entityLabel && aui.section === sectionName);
        newEstrazione.splice(index, 1);
        const newAui = { ...aui, valid: value, updated: true, valid_status: true };
        newEstrazione.splice(index, 0, newAui);
        setAuiSelection(newEstrazione);
    }

    function sendProgressivoTrue() {
        setIsLoading(true);
        updateOne('pratiche', data._id, {
            codice_utente: authProvider?.getAccount()?.userName,
            connessione_operazione: { progressivo: true },
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Progressivo assegnato',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function setIntermediarioSelected(entityLabel: any, intermediarioSelected: any, value: any) {
        const newIntermediarioSelection = [...intermediarioSelection];
        const index = intermediarioSelection.findIndex((inter) => inter.entity_label === entityLabel);
        newIntermediarioSelection.splice(index, 1);
        const newIntermediario = { ...intermediarioSelected, valid: value, updated: true };
        newIntermediarioSelection.splice(index, 0, newIntermediario);
        setIntermediarioSelection(newIntermediarioSelection);
    }

    function getAuiInput(entityLabel: string, sectionName: string) {
        const retrievedExtraction = data?.estrazione?.find(
            (e: any) => e.entity_label === entityLabel && e.section === sectionName,
        );

        const aui = auiSelection?.find((aui) => aui.entity_label === entityLabel && aui.section === sectionName);

        if (data?.status?.manual) return <Box>{retrievedExtraction.valid}</Box>;
        if (typeof retrievedExtraction?.valid === 'boolean')
            return (
                <Checkbox
                    borderColor="gray.500"
                    id={entityLabel.concat('_valid')}
                    isChecked={
                        auiSelection?.find(
                            (aui) => aui.entity_label === entityLabel && aui.section === sectionName && aui.valid,
                        ) !== undefined
                    }
                    isDisabled={!data?.presa_in_carico}
                    onChange={(e) => {
                        setSelection(entityLabel, sectionName, aui, e.target.checked);
                    }}
                />
            );
        if (retrievedExtraction?.type === 'select') {
            return (
                <Select
                    size="sm"
                    placeholder={'Seleziona valore'}
                    value={aui?.valid ? aui.valid.toLowerCase() : ''}
                    onChange={(event) => {
                        setSelection(entityLabel, sectionName, aui, event.target.value);
                    }}
                    disabled={!data?.presa_in_carico}
                >
                    {retrievedExtraction?.options.map((option: string) => (
                        <option key={option.concat('_selection')} value={option.toLowerCase()}>
                            {option}
                        </option>
                    ))}
                </Select>
            );
        }
        if (retrievedExtraction?.type === 'date') {
            return (
                <DatePicker
                    style={{ padding: '0' }}
                    onChange={(selectedDate) => {
                        setSelection(
                            entityLabel,
                            sectionName,
                            aui,
                            selectedDate ? getDateFormatted(selectedDate, false) : '',
                        );
                    }}
                    selectedDate={
                        aui?.valid ? new Date(moment(aui.valid, 'DD-MM-YYYY').format('MM-DD-YYYY')) : undefined
                    }
                    startDate={new Date()}
                    isRange={false}
                    isClearable={true}
                    disabled={!data?.presa_in_carico}
                />
            );
        }
        return (
            <Box>
                <Input
                    size="sm"
                    placeholder={'Inserisci manualmente valore'}
                    onChange={(event) => {
                        setSelection(entityLabel, sectionName, aui, event.target.value);
                        setIsInputError({
                            ...isInputError,
                            [sectionName.concat('_').concat(entityLabel)]:
                                event.target.value &&
                                !matchPattern(retrievedExtraction?.pattern).test(event.target.value),
                        });
                    }}
                    value={aui?.valid}
                    disabled={!data?.presa_in_carico}
                    isInvalid={aui?.valid && !matchPattern(retrievedExtraction?.pattern).test(aui?.valid)}
                />
                {aui?.valid && !matchPattern(retrievedExtraction?.pattern).test(aui?.valid) && (
                    <Text fontSize="xs" color="red">
                        Formato non corretto
                    </Text>
                )}
            </Box>
        );
    }

    function getIntermediarioInput(entityLabel: string) {
        const retrievedIntermediario = data?.intermediario_entities?.find((e: any) => e.entity_label === entityLabel);

        const intermediarioSelected = intermediarioSelection?.find((inter) => inter.entity_label === entityLabel);

        if (data?.status?.manual) return <Box>{retrievedIntermediario.valid}</Box>;
        if (typeof retrievedIntermediario?.valid === 'boolean')
            return (
                <Checkbox
                    borderColor="gray.500"
                    id={entityLabel.concat('_valid')}
                    isChecked={auiSelection?.find((aui) => aui.entity_label === entityLabel && aui.valid) !== undefined}
                    isDisabled={!data?.presa_in_carico}
                    onChange={(e) => {
                        setIntermediarioSelected(entityLabel, intermediarioSelected, e.target.checked);
                    }}
                />
            );
        if (retrievedIntermediario?.type === 'select') {
            return (
                <Select
                    size="sm"
                    placeholder={'Seleziona valore'}
                    value={intermediarioSelected?.valid ? intermediarioSelected.valid.toLowerCase() : ''}
                    onChange={(event) => {
                        setIntermediarioSelected(entityLabel, intermediarioSelected, event.target.value);
                    }}
                    disabled={!data?.presa_in_carico}
                >
                    {retrievedIntermediario?.options.map((option: string) => (
                        <option key={option.concat('_selection')} value={option.toLowerCase()}>
                            {option}
                        </option>
                    ))}
                </Select>
            );
        }
        if (retrievedIntermediario?.type === 'date') {
            return (
                <DatePicker
                    style={{ padding: '0' }}
                    onChange={(selectedDate) => {
                        setIntermediarioSelected(
                            entityLabel,
                            intermediarioSelected,
                            selectedDate ? getDateFormatted(selectedDate, false) : '',
                        );
                    }}
                    selectedDate={
                        intermediarioSelected?.valid
                            ? new Date(moment(intermediarioSelected.valid, 'DD-MM-YYYY').format('MM-DD-YYYY'))
                            : undefined
                    }
                    startDate={new Date()}
                    isRange={false}
                    isClearable={true}
                    disabled={!data?.presa_in_carico}
                />
            );
        }
        return (
            <Box>
                <Input
                    size="sm"
                    placeholder={'Inserisci manualmente valore'}
                    onChange={(event) => {
                        setIntermediarioSelected(entityLabel, intermediarioSelected, event.target.value);
                    }}
                    value={intermediarioSelected?.valid}
                    disabled={!data?.presa_in_carico}
                />
            </Box>
        );
    }

    function getConnessioneOpearzioneInput(entityLabel: string) {
        if (data?.status?.manual) return <Box>{data?.connessione_operazione?.valid}</Box>;
        return (
            <Box>
                <Input
                    size="sm"
                    placeholder={'Inserisci manualmente valore'}
                    onChange={(event) => {
                        setConnOperazioneSelection({
                            ...connOperazioneSelection,
                            valid: event.target.value,
                            updated: true,
                        });
                    }}
                    value={connOperazioneSelection?.valid}
                    disabled={!data?.presa_in_carico}
                />
            </Box>
        );
    }

    function getCheckBoxAuiSelection(
        entityLabel: string,
        value: any,
        key: string,
        type: 'extracted_value' | 'legacy_value' | 'wintar_value',
        sectionName: string,
    ) {
        const retrievedExtraction = data?.estrazione?.find(
            (e: any) => e.entity_label === entityLabel && e.section === sectionName,
        );
        if (typeof value === 'boolean') {
            let newValue = '';
            if (value) newValue = 'PRESENTE';
            else newValue = 'NON PRESENTE';
            return <Box id={key}>{newValue}</Box>;
        }
        return (
            <Flex justifyContent="space-between">
                <Box id={key}>{value}</Box>
                {!retrievedExtraction?.valid_status &&
                    ((type === 'legacy_value' && retrievedExtraction?.legacy_value !== 'NA') ||
                        type === 'extracted_value' ||
                        type === 'wintar_value') &&
                    auiSelection?.find(
                        (aui) => aui.entity_label === entityLabel && aui.section === sectionName && aui[type] !== '',
                    ) &&
                    retrievedExtraction.valid_status !== null && (
                        <Checkbox
                            borderColor="gray.500"
                            id={key}
                            isDisabled={!data?.presa_in_carico}
                            isChecked={
                                auiSelection?.find(
                                    (aui) =>
                                        aui.entity_label === entityLabel &&
                                        aui.section === sectionName &&
                                        aui[type] === value &&
                                        aui.valid === value,
                                ) !== undefined
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const newEstrazione = [...auiSelection];
                                    const index = auiSelection.findIndex(
                                        (aui) => aui.entity_label === entityLabel && aui.section === sectionName,
                                    );
                                    newEstrazione.splice(index, 1);
                                    const newAui = {
                                        ...retrievedExtraction,
                                        valid: value,
                                        updated: true,
                                        valid_status: true,
                                    };
                                    newEstrazione.splice(index, 0, newAui);
                                    setAuiSelection(newEstrazione);
                                }
                            }}
                        />
                    )}
            </Flex>
        );
    }

    function getStatusIcon(valid: boolean | null) {
        if (valid === null) return <Icon mr="2" boxSize="4" as={FcMediumPriority} />;
        else
            return valid ? (
                <Icon mr="2" boxSize="4" as={FcApproval} />
            ) : (
                <Icon mr="2" boxSize="4" as={FcHighPriority} />
            );
    }

    function cancelDuplicate() {
        setIsLoading(true);
        updateOne('pratiche', data._id, {
            codice_utente: authProvider?.getAccount()?.userName,
            status: { doppione: null },
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Duplicato annullato con successo',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function closePratica() {
        setIsLoading(true);
        updateOne('pratiche', data._id, {
            codice_utente: authProvider?.getAccount()?.userName,
            status: { chiuso: true },
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Pratica chiusa con successo',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function saveAuiSelection() {
        setIsLoading(true);
        const newSelection = auiSelection?.map((aui) => {
            if (aui.valid_status === null && !aui.valid) {
                return { ...aui, valid_status: true, valid: aui.extracted_value };
            } else {
                return { ...aui };
            }
        });
        const newIntermediarioSelection = intermediarioSelection?.map((inter) => {
            if (!inter.valid) {
                return { ...inter, valid: inter.extracted_value };
            } else {
                return { ...inter };
            }
        });
        if (!connOperazioneSelection?.valid) connOperazioneSelection.valid = connOperazioneSelection.wintar_value;
        updateOne('pratiche', data._id, {
            estrazione: newSelection,
            intermediario_entities: newIntermediarioSelection,
            connessione_operazione: connOperazioneSelection,
            ricerca_wintar: wintarSearch,
            codice_utente: authProvider?.getAccount()?.userName,
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: "Richiesta inviata con successo. L'esito sarà disponibile a breve, ritornare dopo sulla pratica.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const [auiSelection, setAuiSelection] = useState<any[]>([]);
    const [intermediarioSelection, setIntermediarioSelection] = useState<any[]>([]);
    const [connOperazioneSelection, setConnOperazioneSelection] = useState<any>();
    const [errorsSelection, setErrorsSelection] = useState<string[]>([]);
    const [errorText, setErrorText] = useState<string>('');
    const [isModalWarningManualModeOpen, setIsModalWarningManualModeOpen] = useState<boolean>(true);
    const [isInputError, setIsInputError] = useState<any>({});
    const [noteToDocumentaleSelection, setNoteToDocumentaleSelection] = useState<any>([]);
    const [emails, setEmails] = useState<string[]>(data?.discarded_receivers);
    const [wintarSearch, setWintarSearch] = useState<boolean>(false);

    useEffect(() => {
        setAuiSelection(data.estrazione);
        setIntermediarioSelection(data?.intermediario_entities);
        setConnOperazioneSelection(data?.connessione_operazione);
        setIsLoading(data.processing);
        setEmails(data.discarded_receivers);
        if (data?.processing) setLoaderMessage('Pratica in elaborazione, attendere prego.');
        else setLoaderMessage('');
    }, [data]);

    const isAuiSelectionOk =
        !data?.presa_in_carico ||
        (_.isEqual(auiSelection, data?.estrazione) &&
            data?.estrazione &&
            !data?.estrazione.find((entity: any) => entity.valid_status === null) &&
            _.isEqual(intermediarioSelection, data?.intermediario_entities) &&
            _.isEqual(connOperazioneSelection, data?.connessione_operazione));

    function moveDocument(document: IDocument | null) {
        setIsLoading(true);
        if (!document)
            return toast({
                title: 'Nessun documento selezionato',
                status: 'warning',
                duration: 9000,
                isClosable: true,
            });
        updateOne('documenti', document._id, { pratica: data._id, codice_utente: authProvider?.getAccount()?.userName })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Documento '.concat(document.nome_file).concat(' spostato con successo'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const estrazioneBySection = _.groupBy(data.estrazione, 'section');

    function sendErrorsEmail() {
        setIsLoading(true);
        createOne('pratiche/send/email/'.concat(data._id), {
            codice_utente: authProvider?.getAccount()?.userName,
            errors_to_send: errorsSelection,
            text: errorText ? errorText : '',
            emails,
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Richiesta email inviata con successo',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: 'Errore imprevisto',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function getBase64(file: any, callback: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            return callback(reader.result);
        };
    }

    function setPraticaToManualMode() {
        const toast = createStandaloneToast();
        setIsLoading(true);
        const praticaID = praticaManualModalWarningData;
        setPraticaManualModalWarningData(null);
        updateOne('pratiche/manual', praticaID, {
            codice_utente: authProvider?.getAccount()?.userName,
            status: {
                scarto_wintar: false,
                scarto_legacy: false,
                scarto_firme: false,
                scarto_documentale: false,
                manual: true,
            },
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                return toast({
                    title: 'Pratica spostata in modalità manuale',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                return toast({
                    title: error,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function drawNoteDocumentaleBody(): any {
        const noteColumns = [
            {
                label: (
                    <Checkbox
                        id={'selectAllNote'}
                        borderColor="gray.500"
                        isChecked={
                            noteToDocumentaleSelection &&
                            data?.note &&
                            noteToDocumentaleSelection.length === data?.note.length
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                setNoteToDocumentaleSelection(data?.note);
                            } else {
                                setNoteToDocumentaleSelection([]);
                            }
                        }}
                    />
                ),
                value: 'text',
                render: (key: string, value: any) => (
                    <Td key={key} maxW="1rem" minW={'1rem'}>
                        <Checkbox
                            id={value?.replace(/\s/g, '')}
                            borderColor="gray.500"
                            isChecked={
                                noteToDocumentaleSelection?.find((nota: any) => nota?.text === value) !== undefined
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const newNoteSelection = [...noteToDocumentaleSelection];
                                    newNoteSelection.push(data?.note.find((nota: any) => nota?.text === value));
                                    setNoteToDocumentaleSelection(newNoteSelection);
                                } else {
                                    const newNoteSelection = [...noteToDocumentaleSelection];
                                    const index = newNoteSelection.findIndex((nota) => nota?.text === value);
                                    newNoteSelection.splice(index, 1);
                                    setNoteToDocumentaleSelection(newNoteSelection);
                                }
                            }}
                        />
                    </Td>
                ),
            },
            {
                label: 'Note',
                value: 'text',
                render: (key: string, value: any) => (
                    <Td key={key}>
                        <Text fontWeight={'bold'}>{value}</Text>
                    </Td>
                ),
            },
        ];
        return <BodyTable columns={noteColumns} data={data?.note} />;
    }

    function sendPraticaToDocumentale() {
        if (!data || !data._id) return;
        setIsLoading(true);
        createOne('pratiche/documentale/'.concat(data._id), {
            codice_utente: authProvider?.getAccount()?.userName,
            note: noteToDocumentaleSelection,
        })
            .then((response) => {
                setIsLoading(false);
                props.reloadData();
                setShowDocumentaleModal(false);
                return toast({
                    title: 'Pratica inviata a documentale correttamente',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                setIsLoading(false);
                setShowDocumentaleModal(false);
                return toast({
                    title: error,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    function isAttachmentExtensionAccepted(extension) {
        return extension === 'pdf' || extension === 'jpg' || extension === 'jpeg' || extension === 'png';
    }

    return (
        <>
            <Modal
                isOpen={!!praticaManualModalWarningData}
                onClose={() => {
                    setIsModalWarningManualModeOpen(false);
                    setPraticaManualModalWarningData(null);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Imposta pratica in gestione manuale</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ alignSelf: 'center' }}>
                        Sei sicuro di voler impostare la pratica in gestione manuale?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="ghost"
                            mr={3}
                            onClick={() => {
                                setIsModalWarningManualModeOpen(false);
                                setPraticaManualModalWarningData(null);
                            }}
                        >
                            Annulla
                        </Button>
                        <Button colorScheme="blue" onClick={setPraticaToManualMode}>
                            Conferma
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={!!showDocumentaleModal}
                onClose={() => {
                    setShowDocumentaleModal(false);
                }}
                size={'2xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Seleziona note da inviare a documentale</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ alignSelf: 'center' }}>{drawNoteDocumentaleBody()}</ModalBody>
                    <ModalFooter>
                        <Button
                            variant="ghost"
                            mr={3}
                            onClick={() => {
                                setShowDocumentaleModal(false);
                            }}
                        >
                            Annulla
                        </Button>
                        <Button colorScheme="blue" onClick={sendPraticaToDocumentale}>
                            Invia a documentale
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {data?.status?.doppione && (
                <Flex>
                    <Box w="30.55%">
                        <Heading size="md" ml={'1rem'} mb={'1rem'} color={'red'}>
                            {'Pratica lavorata su Wintar'}
                        </Heading>
                    </Box>
                    <Box>
                        <Button
                            colorScheme="yellow"
                            isDisabled={!data?.presa_in_carico}
                            onClick={cancelDuplicate}
                            size="sm"
                        >
                            Annulla duplicato
                        </Button>
                        <Button
                            ml="0.6rem"
                            colorScheme="yellow"
                            isDisabled={!data?.presa_in_carico}
                            onClick={closePratica}
                            size="sm"
                        >
                            Chiudi pratica
                        </Button>
                    </Box>
                </Flex>
            )}
            {data?.status?.manual_check_required && (
                <Box>
                    <Heading size="md" ml={'1rem'} mb={'1rem'} color={'red'}>
                        {'Controllare email'}
                    </Heading>
                </Box>
            )}
            {(data?.status?.manual_writing_pagamento || data?.status?.manual_writing_yolo) && (
                <Box>
                    <Heading size="md" ml={'1rem'} mb={'1rem'} color={'red'}>
                        {'Controllare modulo MIC'}
                    </Heading>
                </Box>
            )}
            {data?.status?.tipo_rapporto_different && (
                <Box>
                    <Heading size="md" ml={'1rem'} mb={'1rem'} color={'red'}>
                        {'Attenzione - incongruenza tipo rapporto wintar e contraenza legacy'}
                    </Heading>
                </Box>
            )}
            {data?.status?.annullato && (
                <Box>
                    <Heading size="md" ml={'1rem'} mb={'1rem'} color={'red'}>
                        {'Pratica annullata'}
                    </Heading>
                </Box>
            )}
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Invio Errori</Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {data?.errors && data?.errors.length > 0 ? (
                        <Flex justifyContent="space-between" my="8" pr="2">
                            <VStack align="stretch">
                                <SimpleGrid columns={3} spacing={3}>
                                    {data.errors?.map((errore: any) => (
                                        <Tag
                                            size={'md'}
                                            height={'20px'}
                                            key={errore.codice}
                                            cursor="pointer"
                                            variant={
                                                errorsSelection.find((error) => error === errore.codice)
                                                    ? 'solid'
                                                    : 'subtle'
                                            }
                                            colorScheme="red"
                                            onClick={() => {
                                                if (errorsSelection.find((error) => error === errore.codice)) {
                                                    const newErrorSelection = errorsSelection.filter(
                                                        (error) => error !== errore.codice,
                                                    );
                                                    setErrorsSelection(newErrorSelection);
                                                } else {
                                                    const newErrorSelection = [...errorsSelection];
                                                    newErrorSelection.push(errore.codice);
                                                    setErrorsSelection(newErrorSelection);
                                                }
                                            }}
                                        >
                                            <TagLeftIcon
                                                boxSize="12px"
                                                as={
                                                    errorsSelection.find((error) => error === errore.codice)
                                                        ? MinusIcon
                                                        : AddIcon
                                                }
                                            />
                                            <TagLabel>{errore.label}</TagLabel>
                                        </Tag>
                                    ))}
                                </SimpleGrid>
                                <Box width="30rem">
                                    <EmailChipInput onEmailsChange={(emails) => setEmails(emails)} emails={emails} />
                                </Box>
                                <Box>
                                    <Textarea
                                        resize="none"
                                        value={errorText}
                                        onChange={(e) => setErrorText(e.target.value)}
                                        placeholder="Inserisci testo aggiuntivo"
                                        size="sm"
                                    />
                                </Box>
                            </VStack>
                            <HStack>
                                <Button
                                    ml="1rem"
                                    colorScheme="yellow"
                                    isDisabled={(errorsSelection.length === 0 && !errorText) || !data?.presa_in_carico}
                                    onClick={sendErrorsEmail}
                                >
                                    Invia richiesta Email
                                </Button>
                            </HStack>
                        </Flex>
                    ) : (
                        <div>
                            <Text>Nessun errore rilevato</Text>
                        </div>
                    )}
                </AccordionPanel>
            </AccordionItem>
            <input
                type="file"
                hidden
                ref={(e) => {
                    inputRef.current = e;
                }}
                onChange={(e) => {
                    if (e.target.files) {
                        const file: any = e.target.files[0];
                        const extension = file.name.toLowerCase().split('.').pop();
                        if (!isAttachmentExtensionAccepted(extension))
                            return toast({
                                title: 'Formato file non supporato',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            });
                        setIsLoading(true);
                        getBase64(file, (base64: string) => {
                            const fileToSend = { content: base64, filename: file.name };
                            overwriteOne('/documenti/upload', data._id, fileToSend)
                                .then((response) => {
                                    setIsLoading(false);
                                    e.target.files = null;
                                    props.reloadData();
                                    return toast({
                                        title: 'Documento caricato con successo',
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                })
                                .catch((error) => {
                                    setIsLoading(false);
                                    return toast({
                                        title: error,
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                });
                        });
                    }
                }}
            />
            {data?.lista_email_risposte && data?.lista_email_risposte.length > 0 && (
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            <Heading size="md">Risposte agenzia</Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {data?.lista_email_risposte?.map((email: any, index: number) => (
                            <EmailTread key={index} email={email} />
                        ))}
                    </AccordionPanel>
                </AccordionItem>
            )}
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Documenti Associati</Heading>
                    </Box>
                    <Button
                        as="a"
                        onClick={(e) => {
                            e.preventDefault();
                            moveDocument(document);
                        }}
                        variant="link"
                        colorScheme="blue"
                        size="sm"
                        mr="4"
                    >
                        Sposta qui il Documento che hai selezionato
                    </Button>
                    <Button
                        as="a"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            inputRef.current?.click();
                        }}
                        variant="link"
                        colorScheme="blue"
                        size="sm"
                        mr="4"
                    >
                        <Text>Carica documento</Text>
                        <Text fontSize="xs" color="gray.400" ml="0.2rem">
                            (.pdf,.jpg,.jpeg,.png)
                        </Text>
                    </Button>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {data?.documenti?.map((document: IDocument, index: number) => (
                        <Document
                            key={index}
                            doc={{ ...document, pratica: { id: data._id, label_id: data.label_id } }}
                            documentSize={data.documenti.length}
                            setIsLoading={setIsLoading}
                            reloadData={props.reloadData}
                        />
                    ))}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Modulo MIC Elaborato</Heading>
                    </Box>
                    {getStatusIcon(
                        data?.estrazione?.find((estrazione: any) => estrazione.valid_status === null) &&
                            !data?.estrazione?.find((estrazione: any) => estrazione.valid_status === false)
                            ? null
                            : !data?.estrazione?.find((estrazione: any) => !estrazione.valid_status),
                    )}
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {/*<Flex justifyContent="space-between" my="8" pr="2">*/}
                    {/*    <Heading size="sm" fontWeight="300">*/}
                    {/*        Controllo Firme*/}
                    {/*    </Heading>*/}
                    {/*    {data?.status?.scarto_firme ? <FcHighPriority /> : <FcApproval />}*/}
                    {/*</Flex>*/}
                    {/*<Flex justifyContent="space-between" my="8" pr="2">*/}
                    {/*    <Box w={400}>*/}
                    {/*        <Image*/}
                    {/*            src={*/}
                    {/*                data?.status?.scarto_firme*/}
                    {/*                    ? data?.numero_polizza === '3029140'*/}
                    {/*                        ? 'https://drive.google.com/u/0/uc?id=1DPgTAeWuQq6OthosqmwOTALBx-3F4UbD&export=download'*/}
                    {/*                        : 'https://drive.google.com/u/0/uc?id=1GcSZ5e2qb81wsuTGejnA3vinarfHrJ5K&export=download'*/}
                    {/*                    : 'https://drive.google.com/u/0/uc?id=1GcSZ5e2qb81wsuTGejnA3vinarfHrJ5K&export=download'*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </Box>*/}
                    {/*    <HStack spacing={8}>*/}
                    {/*        {data?.status?.scarto_firme && <Button colorScheme="green">Approva</Button>}*/}
                    {/*        <Button colorScheme="yellow">Invia richiesta Email</Button>*/}
                    {/*    </HStack>*/}
                    {/*</Flex>*/}
                    <Box>
                        <Flex justifyContent="space-between" my="8" pr="2">
                            <Heading size="sm" fontWeight="300">
                                Controlli di conformità
                            </Heading>
                            {data?.estrazione?.length > 0 ? (
                                <Flex alignItems="center">
                                    <Box mr={'1rem'}>
                                        Wintar
                                        <Switch
                                            id="wintar_enabled"
                                            isChecked={wintarSearch}
                                            onChange={(e) => setWintarSearch(e.target.checked)}
                                            ml={'0.5rem'}
                                        />
                                    </Box>
                                    <Button
                                        colorScheme={!wintarSearch && isAuiSelectionOk ? 'gray' : 'green'}
                                        isDisabled={(!wintarSearch && isAuiSelectionOk) || _.some(isInputError)}
                                        mr={2}
                                        onClick={saveAuiSelection}
                                    >
                                        Applica
                                    </Button>
                                </Flex>
                            ) : (
                                <FcApproval />
                            )}
                        </Flex>
                        {estrazioneBySection &&
                            Object.keys(estrazioneBySection).length > 0 &&
                            Object.keys(estrazioneBySection).map((sezione: any) => {
                                return (
                                    <AccordionItem key={sezione}>
                                        <AccordionButton>
                                            {getStatusIcon(
                                                estrazioneBySection[sezione].find(
                                                    (estrazione) => estrazione.valid_status === null,
                                                ) &&
                                                    !estrazioneBySection[sezione].find(
                                                        (estrazione) => estrazione.valid_status === false,
                                                    )
                                                    ? null
                                                    : !estrazioneBySection[sezione].find(
                                                          (estrazione) => !estrazione.valid_status,
                                                      ),
                                            )}
                                            <Box flex="1" textAlign="left">
                                                {sectionNameLabel[sezione] || sezione}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <BodyTable
                                                key={sezione}
                                                customKey={sezione}
                                                columns={checkLegacyColumns}
                                                data={estrazioneBySection[sezione]}
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                        {data?.intermediario_entities && (
                            <AccordionItem key="intermediario_entities">
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        {'Dati intermediario'}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <BodyTable
                                        key={'table_intermediario'}
                                        columns={intermediarioColumns}
                                        data={data?.intermediario_entities}
                                        customKey={'intermediario'}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        )}
                        <AccordionItem key="connessione_operazione_accordion">
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    {'Connessione Operazione'}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <BodyTable
                                    key={'table_intermediario'}
                                    columns={connessioneOperazioneColumns}
                                    data={data?.connessione_operazione ? [data?.connessione_operazione] : []}
                                    customKey={'conn_operazione'}
                                />
                            </AccordionPanel>
                        </AccordionItem>
                    </Box>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Heading size="md">Bacheca</Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Bacheca
                        messages={data.note}
                        reloadData={props.reloadData}
                        id={data._id}
                        type={'pratiche'}
                        isDisabled={!data?.presa_in_carico}
                    />
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}
