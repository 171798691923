import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Table } from 'components/shared/Table';

const types = ['email', 'pratiche', 'fascicoli', 'documenti', 'autoesitati'];

export function List() {
    const { type } = useParams<{ type: 'email' | 'pratiche' | 'fascicoli' | 'documenti' | 'autoesitati' }>();
    const history = useHistory();

    useEffect(() => {
        if (!types.includes(type)) {
            history.push('/dashboard');
        }
    }, [type]);

    return <Table type={type} />;
}
