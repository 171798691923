import { useContext } from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import { Link, useToast } from '@chakra-ui/react';
import { AppContext, IContext } from '../../contexts/AppContext';
import { getPraticheStatusIcon } from './Table';

export function Pratica({ pratica }: { pratica: { [key: string]: any } }) {
    const { setPratica } = useContext(AppContext) as IContext;
    const toast = useToast();
    return (
        <Box border="1px solid" bg="white" borderColor="gray.100" borderRadius="xl" my="4" p="4" fontSize="sm">
            <Flex>
                <Box w="30%">Nome: </Box>
                <Box fontWeight="600" color="blue.400">
                    <Link href={`/pratiche/${pratica._id}`}>{pratica.label_id}</Link>
                </Box>
                <Button
                    onClick={() => {
                        setPratica(pratica);
                        toast({
                            title: 'Pratica '.concat(pratica._id).concat(' copiata'),
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        });
                    }}
                    variant="link"
                    colorScheme="blue"
                    size="sm"
                    ml="4"
                >
                    Sposta in un altro fascicolo
                </Button>
            </Flex>
            <Flex>
                <Box w="30%">Compagnia: </Box>
                <Box fontWeight="600">{pratica.compagnia}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Tipologia: </Box>
                <Box fontWeight="600">{pratica.tipo_operazione}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Numero polizza: </Box>
                <Box fontWeight="600">{pratica.numero_polizza}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Data: </Box>
                <Box fontWeight="600">{pratica.data_operazione}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Contraente: </Box>
                <Box fontWeight="600">{pratica.contraente}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Importo: </Box>
                <Box fontWeight="600">{pratica.importo}</Box>
            </Flex>
            <Flex>
                <Box w="30%">Stato: </Box>
                <Box fontWeight="600">
                    {getPraticheStatusIcon(pratica.status, pratica?.wintar_response?.wintar_exists)}
                </Box>
            </Flex>
        </Box>
    );
}
