import { useState } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { Flex, Button, Spacer, Box, IconButton } from '@chakra-ui/react';
import { Table as CTable, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { CgChevronDown, CgChevronUp } from 'react-icons/cg';
import styled from 'styled-components';
import { LinkButton } from '../React-Doc-Viewer/components/common';
import { IStyledProps } from '../React-Doc-Viewer/types';

const DownloadButton = styled(LinkButton)`
    width: 130px;
    height: 30px;
    background-color: ${(props: IStyledProps) => props.theme.primary};
    @media (max-width: 768px) {
        width: 125px;
        height: 25px;
    }
`;

interface Props {
    columns: any[];
    changeColumnSort?: (value: string) => any;
    data: { [key: string]: any };
    onDoubleClick?: (id: number) => any;
    filters?: { [key: string]: any };
    customKey?: string;
    buttons?: { [key: string]: any };
}

export function BodyTable({
    columns = [],
    changeColumnSort = () => null,
    onDoubleClick = () => null,
    customKey = '',
    ...props
}: Props) {
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    return (
        <CTable variant="simple" size="sm">
            <Thead>
                <Tr gridColumnGap="10px">
                    {columns
                        .filter((c) => !c.notShow)
                        .map((column) => (
                            <Th
                                key={column.value.concat('-').concat(customKey).concat('-').concat(column.label)}
                                cursor="pointer"
                                isNumeric={column.type === 'number'}
                                onClick={() => changeColumnSort(column.value)}
                            >
                                <Flex>
                                    {column.label}
                                    {props.filters?.order_by && props.filters?.order_by === column.value ? (
                                        props.filters.order === 1 ? (
                                            <CgChevronDown />
                                        ) : (
                                            <CgChevronUp />
                                        )
                                    ) : (
                                        ''
                                    )}
                                    {props.buttons?.download && column.download && (
                                        <IconButton
                                            aria-label={`Stats ${column.label} file download`}
                                            ml="20px"
                                            boxSize={4}
                                            variant="outline"
                                            color="#3182ce"
                                            icon={<DownloadIcon />}
                                            onClick={() => props.buttons?.download.onClick(column.value)}
                                            isDisabled={props.buttons?.download.isDisabled}
                                        />
                                    )}
                                </Flex>
                            </Th>
                        ))}
                </Tr>
            </Thead>
            <Tbody>
                {props.data
                    ? props.data?.map((row: any, i: number) => (
                          <Tr
                              key={i.toString().concat(customKey)}
                              cursor="pointer"
                              onClick={() => setSelectedRow(i)}
                              onDoubleClick={() => onDoubleClick(row._id)}
                              bg={i === selectedRow ? 'blackAlpha.50' : 'transparent'}
                          >
                              {columns.filter((c) => !c.notShow).map((column) => getRow(column, row, i))}
                          </Tr>
                      ))
                    : null}
            </Tbody>
        </CTable>
    );

    function getRow(column: { [key: string]: any }, row: any, i: number) {
        const valueField = columns.find((field) => field.keyField)?.value;
        if (column?.render) {
            return column.render(column.value, row[column.value], row[valueField], row?.section, row, i);
        }

        return (
            <Td
                key={column.value.concat(i).concat(customKey)}
                isNumeric={column?.type === 'number'}
                {...column?.styles}
            >
                {row[column.value]}
            </Td>
        );
    }
}
