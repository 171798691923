import axios from 'axios';
import urljoin from 'url-join';
import { errorHandler, successHandler } from './interceptors';

// SE IN PRODUZIONE PRENDE IN AUTOMATICO L'URL DEL SERVER COME BASEURL
const baseURL = process.env.REACT_APP_BASE_URL || '';
//const baseURL = "http://localhost:8080/"
const basePath = '/services';

const http = axios.create({
    baseURL: urljoin(baseURL, basePath),
});

http.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error),
);

export { baseURL, http, basePath };
