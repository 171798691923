export function matchPattern(pattern: string) {
    let regex: any = /^/;
    switch (pattern) {
        case 'cf_piva':
            regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$|^[0-9]{11}$/;
            break;
        case 'iban':
            regex = /^IT[0-9]{2}[A-Z][0-9]{10}[0-9A-Z]{12}$/;
            break;
        case 'cf':
            regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
            break;
        case 'ateco_sae':
            regex = /^\d{3}$/;
            break;
        case 'importo_operazione':
            regex = /^[0-9]+(\.[0-9]{1,4})?$/;
            break;
        default:
            regex = /^/;
    }
    return regex;
}
