import { useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/layout';
import { Button, FormControl, FormLabel, HStack, Select, Spacer, Text } from '@chakra-ui/react';
import { Td } from '@chakra-ui/table';
import DatePicker from '../../components/shared/DatePicker/date-picker';
import { Loader } from '../../components/shared/Loader';
import { getDateFormatted } from '../../components/shared/Table';
import { getAll } from '../../services/crud';
import { DashboardBox } from './DashboardBox';

export function Dashboard() {
    const [dateReceivedRange, setDateReceivedRange] = useState<Array<any>>([undefined, undefined]);
    const [dateManagedRange, setDateManagedRange] = useState<Array<any>>([undefined, undefined]);
    const [company, setCompany] = useState<string | undefined>(undefined);
    const [emailStats, setEmailStats] = useState<any>([]);
    const [micStats, setMicStats] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [mouseOnMicColumn, setMouseOnMicColumn] = useState<boolean>(false);
    const [mouseOnReceivedColumn, setMouseOnReceivedColumn] = useState<boolean>(false);

    function getData() {
        setLoading(true);
        const emailParams: any = {};
        if (dateReceivedRange[0]) emailParams.date_from = getDateFormatted(dateReceivedRange[0], false);
        if (dateReceivedRange[1]) emailParams.date_to = getDateFormatted(dateReceivedRange[1], false);
        if (company) emailParams.compagnia = company;
        getAll('/stats/email', { params: { ...emailParams } })
            .then((response) => {
                setLoading(false);
                setEmailStats([response]);
            })
            .catch(() => setLoading(false));
        const micParams: any = {};
        if (dateReceivedRange[0]) micParams.date_received_from = getDateFormatted(dateReceivedRange[0], false);
        if (dateReceivedRange[1]) micParams.date_received_to = getDateFormatted(dateReceivedRange[1], false);
        if (dateManagedRange[0]) micParams.date_managed_from = getDateFormatted(dateManagedRange[0], false);
        if (dateManagedRange[1]) micParams.date_managed_to = getDateFormatted(dateManagedRange[1], false);
        if (company) micParams.compagnia = company;
        getAll('/stats/mic', { params: { ...micParams } })
            .then((response) => {
                setLoading(false);
                setMicStats([response]);
            })
            .catch(() => setLoading(false));
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Loader isLoading={loading}>
            <Box>
                <Box>
                    <Flex>
                        <HStack spacing="24px" width="75%">
                            <FormControl id={'date1'} w="100%">
                                <FormLabel>Data ricezione (Email, MIC)</FormLabel>
                                <DatePicker
                                    onChange={(selectedDate) => setDateReceivedRange(selectedDate)}
                                    selectedDate={dateReceivedRange[0]}
                                    startDate={dateReceivedRange[0]}
                                    endDate={dateReceivedRange[1]}
                                    placeholder={'Inserisci data ricezione'}
                                />
                            </FormControl>
                            <Spacer />
                            <FormControl id="data2" w="100%">
                                <FormLabel>Data lavorazione (MIC)</FormLabel>
                                <DatePicker
                                    onChange={(selectedDate) => setDateManagedRange(selectedDate)}
                                    selectedDate={dateManagedRange[0]}
                                    startDate={dateManagedRange[0]}
                                    endDate={dateManagedRange[1]}
                                    placeholder={'Inserisci data lavorazione'}
                                />
                            </FormControl>
                            <Spacer />
                            <FormControl id="compagnia" w="90%" maxW="180px">
                                <FormLabel>Compagnia</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Seleziona compagnia"
                                    onChange={(event) => setCompany(event.target.value)}
                                    value={company}
                                >
                                    <option key="ITA" value="ITA">
                                        ITA
                                    </option>
                                    <option key="RMA" value="RMA">
                                        RMA
                                    </option>
                                </Select>
                            </FormControl>
                            <FormControl id="control-buttons" alignSelf="end">
                                <Button onClick={getData} colorScheme="blue" size="sm">
                                    Applica
                                </Button>
                                <Button
                                    onClick={() => {
                                        setDateReceivedRange([undefined, undefined]);
                                        setDateManagedRange([undefined, undefined]);
                                        setCompany('');
                                        setLoading(true);
                                        getAll('/stats/email')
                                            .then((response) => {
                                                setLoading(false);
                                                setEmailStats([response]);
                                            })
                                            .catch(() => setLoading(false));
                                        getAll('/stats/mic')
                                            .then((response) => {
                                                setLoading(false);
                                                setMicStats([response]);
                                            })
                                            .catch(() => setLoading(false));
                                    }}
                                    colorScheme="blue"
                                    ml="5"
                                    size="sm"
                                    variant="link"
                                >
                                    Reset
                                </Button>
                            </FormControl>
                        </HStack>
                    </Flex>
                </Box>
                <HStack spacing="20px" pt={'10px'}>
                    <Box>
                        <DashboardBox
                            id="emailTable"
                            height="180px"
                            title="Email"
                            columns={[
                                {
                                    label: 'Ricevute',
                                    value: 'received',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td
                                            id={key}
                                            textAlign="right"
                                            onMouseOver={() => setMouseOnReceivedColumn(true)}
                                            onMouseLeave={() => setMouseOnReceivedColumn(false)}
                                        >
                                            <Text fontWeight="bold" color={mouseOnReceivedColumn ? 'red' : ''}>
                                                {value}
                                            </Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Altro',
                                    value: 'type_other',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold" color={mouseOnReceivedColumn ? 'red' : ''}>
                                                {value}
                                            </Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Mic',
                                    value: 'type_mic',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td
                                            id={key}
                                            textAlign="right"
                                            onMouseOver={() => setMouseOnMicColumn(true)}
                                            onMouseLeave={() => setMouseOnMicColumn(false)}
                                        >
                                            <Text
                                                fontWeight="bold"
                                                color={mouseOnReceivedColumn || mouseOnMicColumn ? 'red' : ''}
                                            >
                                                {value}
                                            </Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Automatiche',
                                    value: 'auto',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold" color={mouseOnMicColumn ? 'red' : ''}>
                                                {value}
                                            </Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Manuali',
                                    value: 'manual',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right" color={mouseOnMicColumn ? 'red' : ''}>
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                            ]}
                            data={emailStats}
                        />
                    </Box>
                    <Box>
                        <DashboardBox
                            id="micTable"
                            bgColor="gray.300"
                            height="180px"
                            title="MIC"
                            columns={[
                                {
                                    label: 'Ricevute',
                                    value: 'received',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Lavorate',
                                    value: 'managed',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                                {
                                    label: 'Manuali',
                                    value: 'manual',
                                    type: 'number',
                                    render: (key: string, value: any) => (
                                        <Td id={key} textAlign="right">
                                            <Text fontWeight="bold">{value}</Text>
                                        </Td>
                                    ),
                                },
                            ]}
                            data={micStats}
                        />
                    </Box>
                </HStack>
            </Box>
        </Loader>
    );
}
