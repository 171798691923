import { Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Main } from 'components/shared/Main';
import { Sidebar } from 'components/shared/Sidebar';
import AppProvider, { AppContext, IContext } from 'contexts/AppContext';
import { Dashboard } from 'views/Dashboard';
import { DashboardStatistiche } from 'views/Dashboard/index_new';
import { Detail } from 'views/Detail';
import { List } from 'views/List';
import { Login } from './views/Login';

interface Props {
    authenticationState: any;
    onLogout: any;
    onLogin: any;
}

export default function App({ authenticationState, onLogin, onLogout }: Props) {
    return authenticationState === 'Authenticated' ? (
        <Box as="section" bg="gray.50" minH="100vh">
            <Router>
                <AppProvider>
                    <Sidebar onLogout={onLogout} />
                    <Main>
                        <Switch>
                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>
                            <Route path="/dashboard_statistiche">
                                <DashboardStatistiche />
                            </Route>

                            <Route exact path="/:type">
                                <List />
                            </Route>

                            <Route path="/:type/:id">
                                <Detail />
                            </Route>

                            <Route path="/">
                                <Redirect to="/email" />
                            </Route>
                        </Switch>
                    </Main>
                </AppProvider>
            </Router>
        </Box>
    ) : (
        <Login onLogin={onLogin} />
    );
}
